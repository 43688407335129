import { BillingInfo, MedicalInsights, MedicalResults, Orders, Subscription, Subscriptions as SubscriptionsType } from '@dayetopia/types';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { Treatment } from '@contracts';

interface MarketingPreferences {
  marketingEmail: boolean;
  newsletterSMS: boolean;
  marketingSMS: boolean;
}

interface UserInfoProps {
  accountId: string;
  firstName: string;
  lastName: string;
  pronouns: string;
  email: string;
  telephone: string;
  profileIcon: string;
  dateOfBirth: string;
  marketingPreferences: MarketingPreferences;
  credits?: any;
  termsAcceptedDate?: string;
  vmsEnabledDate?: string;
  ppcEnabledDate?: string;
  pprEnabledDate?: string;
  hasAcceptedDate?: boolean;
  referralCode?: string;
  personaVerificationId?: string;
  isMfaEnabled: false;
}

export interface HealthProfileProps {
  results: MedicalResults;
  insights: MedicalInsights;
  treatments: Treatment[];
}

interface PurchaseProps {
  orders?: Orders;
  subscriptions?: SubscriptionsType;
  billingInfo?: BillingInfo;
  selectedSubscriptionId: string;
  fetchedAccountData: boolean;
  selectedSub?: Subscription;
}

export enum AccountIcons {
  Initials = 'Initials',
  MediumHair = 'MediumHair',
  LongHair = 'LongHair',
  Bun = 'Bun',
  Afro = 'Afro',
  ShortHair = 'ShortHair'
}

export const userInfo: UserInfoProps = {
  accountId: '',
  firstName: '',
  lastName: '',
  pronouns: '',
  email: '',
  telephone: '',
  dateOfBirth: '',
  profileIcon: AccountIcons.Initials,
  marketingPreferences: {
    marketingEmail: false,
    newsletterSMS: false,
    marketingSMS: false
  },
  credits: [],
  termsAcceptedDate: undefined,
  vmsEnabledDate: undefined,
  ppcEnabledDate: undefined,
  pprEnabledDate: undefined,
  personaVerificationId: undefined,
  isMfaEnabled: false
};

const healthProfile: HealthProfileProps = {
  insights: [],
  results: [],
  treatments: []
};

const purchases: PurchaseProps = {
  orders: [],
  subscriptions: [],
  billingInfo: undefined,
  selectedSubscriptionId: '',
  fetchedAccountData: false
};

export interface UseAccountStoreProps {
  userInfo: UserInfoProps;
  purchases: PurchaseProps;
  healthProfile: HealthProfileProps;
  loading: boolean;
  healthProfileLoaded: boolean;
  setUserInfo: (value: any) => void;
  setPurchases: (value: any) => void;
  setHealthProfile: (value: any) => void;
  setLoading: (value: boolean) => void;
  setHealthProfileLoaded: (value: boolean) => void;
  reset: () => void;
}

export const useAccountStore = create(
  persist<UseAccountStoreProps>(
    (set) => ({
      userInfo,
      setUserInfo: (newValue: any) => set({ userInfo: newValue }),
      purchases,
      setPurchases: (newValue: any) => set({ purchases: newValue }),
      healthProfile,
      setHealthProfile: (newValue: any) => set({ healthProfile: newValue }),
      healthProfileLoaded: false,
      loading: true,
      setLoading: (val: boolean) => set({ loading: val }),
      setHealthProfileLoaded: (val: boolean) => set({ healthProfileLoaded: val }),
      reset: () => set({ userInfo, purchases, healthProfile, healthProfileLoaded: false })
    }),
    {
      name: 'vms-state'
    }
  )
);
