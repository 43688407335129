import { CountryCode } from '@dayetopia/types';
import { HSA_FSA_COUNTRIES } from '@constants';
import { ProductCode } from '@contracts';
import { getProductPricesFromStorage } from './getProductPrice';

export function isHsaFsaAvailable(country: CountryCode, productCode?: ProductCode) {
  const pricesData = getProductPricesFromStorage();

  if (!HSA_FSA_COUNTRIES.includes(country)) {
    return false;
  }

  if (productCode) {
    const hsaFsaEligibleProducts = pricesData.filter((product) => product.isHsaFsaEligible).map((product) => product.id);

    if (!hsaFsaEligibleProducts.includes(productCode)) {
      return false;
    }
  }

  return true;
}
