import { CountryCode, Variant } from '@dayetopia/types';
import { getProductPrices } from '@components/Context';
import { CurrencyObj, PadSizeType, ProductCode } from '@contracts';
import { getAllPadTypes, getPadStackPrices, getPadVariantKeys, isPadsStackBox } from '@utils/pads';

export default async function useBoxPrice(variantsDict: Variant, currency: CurrencyObj, country: CountryCode, boxProductCode = 'tampon_box') {
  if (!variantsDict) return null;
  return getPrice(variantsDict, boxProductCode);

  function calculateVATPrice(price: number, vatPercent: number): number {
    if (vatPercent && vatPercent > 0) {
      const vatAmount = price * (vatPercent / 100);
      return vatAmount;
    }
    return 0;
  }

  async function getPrice(variants: any, productCode: string) {
    let price = 0.0;
    const pricesData = await getProductPrices();
    const padSizeTypes = getAllPadTypes();
    const isPadsStack = isPadsStackBox(country);
    const padsStackPrices = isPadsStack ? await getPadStackPrices(variants, currency, country) : null;
    const padVariantKeys = getPadVariantKeys(country);

    if (pricesData) {
      const product = pricesData.find((itm) => itm.id === productCode);
      if (product) {
        const productVariants = product.variants;
        const vatPercent = product?.vat[country] ?? 0;
        const variantsArray = Object.keys(variants).map((variant) => ({ variant, count: variants[variant] }));

        if (productVariants) {
          variantsArray.forEach(async (configurationItem) => {
            const isPadStackKeyIncluded = Object.values(padVariantKeys).includes(configurationItem.variant as ProductCode);
            const isLegacyPadKeyIncluded = padSizeTypes.includes(configurationItem.variant as PadSizeType);

            if (isPadStackKeyIncluded && padsStackPrices) {
              price += padsStackPrices[configurationItem.variant as PadSizeType];
            } else if (
              configurationItem.variant !== 'initiationbox' &&
              configurationItem.variant !== 'mask' &&
              configurationItem.variant !== 'proviotics' &&
              (!isPadsStack || !isLegacyPadKeyIncluded)
            ) {
              const match = productVariants.find((variant) => variant.id === configurationItem.variant);

              if (match) {
                const matchPrice = match.price.find((variant) => variant.currency === currency.letterCode);
                if (matchPrice) {
                  const VATPrice = calculateVATPrice(matchPrice.amount, vatPercent);
                  const finalItemPrice = (matchPrice.amount + VATPrice)?.toFixed(2);
                  price += configurationItem.count * Number(finalItemPrice);
                }
              }
            }
          });
        }
      }
    }

    return price;
  }
}
