import { CountryCode, Variant, Variants, OldVariants } from '@dayetopia/types';
import useBoxPrice from '@components/useBoxPrice';
import { PAD_STACK_COUNTS, PAD_STACK_PRODUCT_IDS } from '@constants';
import { BoxItem, BoxState, CurrencyObj, PadSizeType, PadsBoxStackState, PadsBoxState, Prices, ProductCode } from '@contracts';
import getProductPrice from './getProductPrice';

export function getAllPadTypes(): PadSizeType[] {
  return Object.values(PadSizeType);
}

export function areVariantsContainingOldPads(variants: Variants | OldVariants) {
  const allOldPadsKeys = getAllPadTypes();

  if (Array.isArray(variants)) {
    return variants.some((variant) => allOldPadsKeys.includes(variant.id as PadSizeType));
  }

  return Object.keys(variants).some((key) => allOldPadsKeys.includes(key as PadSizeType));
}

export function getPadVariantKeys(country: CountryCode) {
  const isStackBox = isPadsStackBox(country);
  const variantPl = isStackBox ? PAD_STACK_PRODUCT_IDS[PadSizeType.LINER] : PadSizeType.LINER;
  const variantPr = isStackBox ? PAD_STACK_PRODUCT_IDS[PadSizeType.REGULAR] : PadSizeType.REGULAR;
  const variantPn = isStackBox ? PAD_STACK_PRODUCT_IDS[PadSizeType.SUPER] : PadSizeType.SUPER;
  const variantPnp = isStackBox ? PAD_STACK_PRODUCT_IDS[PadSizeType.SUPER_PLUS] : PadSizeType.SUPER_PLUS;

  return {
    [PadSizeType.LINER]: variantPl,
    [PadSizeType.REGULAR]: variantPr,
    [PadSizeType.SUPER]: variantPn,
    [PadSizeType.SUPER_PLUS]: variantPnp
  };
}

export function getPadVariantCounts(country: CountryCode) {
  const isStackBox = isPadsStackBox(country);
  const variantPl = isStackBox ? PAD_STACK_PRODUCT_IDS[PadSizeType.LINER] : PadSizeType.LINER;
  const variantPr = isStackBox ? PAD_STACK_PRODUCT_IDS[PadSizeType.REGULAR] : PadSizeType.REGULAR;
  const variantPn = isStackBox ? PAD_STACK_PRODUCT_IDS[PadSizeType.SUPER] : PadSizeType.SUPER;
  const variantPnp = isStackBox ? PAD_STACK_PRODUCT_IDS[PadSizeType.SUPER_PLUS] : PadSizeType.SUPER_PLUS;

  return {
    [PadSizeType.LINER]: variantPl,
    [PadSizeType.REGULAR]: variantPr,
    [PadSizeType.SUPER]: variantPn,
    [PadSizeType.SUPER_PLUS]: variantPnp
  };
}

export function getPadTypeTextByType(type: PadSizeType) {
  const padSizeTypeTexts = getPadSizeTypeTexts();

  return padSizeTypeTexts[type];
}

export function getPadTypeLongTextByType(type: PadSizeType) {
  const padSizeTypeTexts = getPadSizeTypeLongTexts();

  return padSizeTypeTexts[type];
}

export function getPadTypeShortDescriptionByType(type: PadSizeType) {
  const padSizeTypeTexts = getPadSizeTypeShortDescriptions();

  return padSizeTypeTexts[type];
}

export function getLinerVariantKey(country: CountryCode) {
  const isStackBox = isPadsStackBox(country);

  return isStackBox ? PAD_STACK_PRODUCT_IDS[PadSizeType.LINER] : PadSizeType.LINER;
}

export function getRegularVariantKey(country: CountryCode) {
  const isStackBox = isPadsStackBox(country);

  return isStackBox ? PAD_STACK_PRODUCT_IDS[PadSizeType.REGULAR] : PadSizeType.REGULAR;
}

export function getSuperVariantKey(country: CountryCode) {
  const isStackBox = isPadsStackBox(country);

  return isStackBox ? PAD_STACK_PRODUCT_IDS[PadSizeType.SUPER] : PadSizeType.SUPER;
}

export function getSuperPlusVariantKey(country: CountryCode) {
  const isStackBox = isPadsStackBox(country);

  return isStackBox ? PAD_STACK_PRODUCT_IDS[PadSizeType.SUPER_PLUS] : PadSizeType.SUPER_PLUS;
}

export async function getLinerPadsPrice(country: CountryCode, currency: CurrencyObj) {
  const linerVariant = { [getLinerVariantKey(country)]: 1 } as Variant;

  const linerPadPrice = (await useBoxPrice(linerVariant, currency, country, ProductCode.Pads)) || 0;
  return linerPadPrice;
}

export async function getRegularPadsPrice(country: CountryCode, currency: CurrencyObj) {
  const regularVariant = { [getRegularVariantKey(country)]: 1 } as Variant;

  const regularPadPrice = (await useBoxPrice(regularVariant, currency, country, ProductCode.Pads)) || 0;
  return regularPadPrice;
}

export async function getSuperPadsPrice(country: CountryCode, currency: CurrencyObj) {
  const nightPadVariantPrice = { [getSuperVariantKey(country)]: 1 } as Variant;

  const nightPadPrice = (await useBoxPrice(nightPadVariantPrice, currency, country, ProductCode.Pads)) || 0;
  return nightPadPrice;
}

export async function getSuperPlusPadsPrice(country: CountryCode, currency: CurrencyObj) {
  const nightPlusPadVariant = { [getSuperPlusVariantKey(country)]: 1 } as Variant;

  const nightPlusPadPrice = (await useBoxPrice(nightPlusPadVariant, currency, country, ProductCode.Pads)) || 0;
  return nightPlusPadPrice;
}

export async function getPadsPrice(padsBoxStackState: PadsBoxStackState, padsBoxState: PadsBoxState, currency: CurrencyObj, country: CountryCode) {
  const finalPadsBoxState = isPadsStackBox(country)
    ? {
        [PAD_STACK_PRODUCT_IDS[PadSizeType.LINER]]: padsBoxStackState[PadSizeType.LINER],
        [PAD_STACK_PRODUCT_IDS[PadSizeType.REGULAR]]: padsBoxStackState[PadSizeType.REGULAR],
        [PAD_STACK_PRODUCT_IDS[PadSizeType.SUPER]]: padsBoxStackState[PadSizeType.SUPER],
        [PAD_STACK_PRODUCT_IDS[PadSizeType.SUPER_PLUS]]: padsBoxStackState[PadSizeType.SUPER_PLUS]
      }
    : padsBoxState;

  const productPrice = await useBoxPrice(finalPadsBoxState as Variant, currency, country, ProductCode.Pads);

  return productPrice || 0;
}

export function getPadStackPriceFromPrices(prices: Prices, padSize: PadSizeType) {
  switch (padSize) {
    case PadSizeType.LINER:
      return prices.linerPadPrice;
    case PadSizeType.REGULAR:
      return prices.regularPadPrice;
    case PadSizeType.SUPER:
      return prices.nightPadPrice;
    case PadSizeType.SUPER_PLUS:
      return prices.nightPlusPadPrice;
    default:
      return -1;
  }
}

export async function getPadStackPrices(newVariants: Variant, currency: CurrencyObj, country: CountryCode) {
  const plKey = PAD_STACK_PRODUCT_IDS[PadSizeType.LINER];
  const prKey = PAD_STACK_PRODUCT_IDS[PadSizeType.REGULAR];
  const pnKey = PAD_STACK_PRODUCT_IDS[PadSizeType.SUPER];
  const pnpKey = PAD_STACK_PRODUCT_IDS[PadSizeType.SUPER_PLUS];
  const linerPadStackPrice = (await getProductPrice(plKey, currency, country))?.price || 0;
  const regularPadStackPrice = (await getProductPrice(prKey, currency, country))?.price || 0;
  const nightPadStackPrice = (await getProductPrice(pnKey, currency, country))?.price || 0;
  const nightPlusPadStackPrice = (await getProductPrice(pnpKey, currency, country))?.price || 0;

  return {
    [PAD_STACK_PRODUCT_IDS[PadSizeType.LINER]]: linerPadStackPrice * (newVariants[plKey] || 0),
    [PAD_STACK_PRODUCT_IDS[PadSizeType.REGULAR]]: regularPadStackPrice * (newVariants[prKey] || 0),
    [PAD_STACK_PRODUCT_IDS[PadSizeType.SUPER]]: nightPadStackPrice * (newVariants[pnKey] || 0),
    [PAD_STACK_PRODUCT_IDS[PadSizeType.SUPER_PLUS]]: nightPlusPadStackPrice * (newVariants[pnpKey] || 0)
  };
}

export function getPadStackPriceTotalFromPrices(prices: Prices, padsBoxStackState: PadsBoxStackState) {
  return getAllPadTypes().reduce((acc, padSize) => {
    return acc + getPadStackPriceFromPrices(prices, padSize) * padsBoxStackState[padSize];
  }, 0);
}

export function getPadSizeTypeTexts() {
  return {
    [PadSizeType.LINER]: 'components.liners',
    [PadSizeType.REGULAR]: 'components.regular',
    [PadSizeType.SUPER]: 'components.super',
    [PadSizeType.SUPER_PLUS]: 'components.super-plus'
  };
}

export function getPadSizeTypeLongTexts() {
  return {
    [PadSizeType.LINER]: 'components.liners',
    [PadSizeType.REGULAR]: 'components.regular',
    [PadSizeType.SUPER]: 'components.super',
    [PadSizeType.SUPER_PLUS]: 'components.super-plus-long'
  };
}

export function getPadSizeTypeShortDescriptions() {
  return {
    [PadSizeType.LINER]: 'components.liners-short-description',
    [PadSizeType.REGULAR]: 'components.regular-short-description',
    [PadSizeType.SUPER]: 'components.super-short-description',
    [PadSizeType.SUPER_PLUS]: 'components.super-plus-short-description'
  };
}

export function isBoxStatePadsStackBox(boxState: BoxState) {
  return !(boxState as BoxState)?.boxSize;
}

export function isPadsAndStackBox(productCode: ProductCode, country: CountryCode) {
  return productCode === ProductCode.Pads && isPadsStackBox(country);
}

export function isPadsStackBox(country: CountryCode) {
  return true;
}

export function convertPadsStackBoxItems(boxItems: BoxItem[]) {
  return boxItems.map((item) => ({
    ...item,
    count: item.count * PAD_STACK_COUNTS[item?.key as keyof typeof PAD_STACK_COUNTS],
    price: (item.price || 0) / PAD_STACK_COUNTS[item?.key as keyof typeof PAD_STACK_COUNTS]
  }));
}
