import { ProductCode } from '@dayetopia/types';
import Colors from '@components/shared/Colors';
import { ProductData } from '@contracts';
import { getFromLocalStorage } from '@utils/localStorage';

// TODO separate styles from the actual data (color field should probably go into a separate file)
export const productsData: ProductData[] = [
  {
    id: 'tampon_box',
    title: 'Tampons',
    description: 'Organic and hyper-absorbent, the safest tampons in the world',
    color: Colors.mint,
    url: '/products/tampons/',
    category: 'Periodcare',
    showcaseImage:
      'https://images.prismic.io/ecommerce-website/0d4b50b2-9919-4e1e-bb02-64234268b520_home-product-desktop-tampons.webp?auto=compress,format',
    showcaseImageMobile:
      'https://images.prismic.io/ecommerce-website/7cb7a4c5-c3e8-410f-995c-3108303424a9_home-product-mobile-tampons.webp?auto=compress,format'
  },
  {
    id: 'cbd_tampons',
    title: 'CBD Tampons',
    description: 'Organic and hyper-absorbent CBD tampons',
    color: 'linear-gradient(180deg, #F9D07C 0%, #FFFBE7 100%), linear-gradient(180deg, #E5F2E3 0%, #FFFBE7 100%), #E5F2E3',
    url: '/products/cbd-tampons/',
    category: 'Periodcare',
    showcaseImage:
      'https://images.prismic.io/ecommerce-website/498047e3-4d20-4d70-8b36-32240276495c_home-product-desktop-cbdtampons.png?auto=compress,format',
    showcaseImageMobile:
      'https://images.prismic.io/ecommerce-website/2fad0238-299a-47a1-9a01-81f514042886_showcase-cbd-tampons%402x.png?auto=compress,format'
  },
  {
    id: 'pads_box',
    title: 'Menstrual Pads',
    description: 'Super-thin, yet highly absorbent compostable bamboo pads',
    color: Colors.mint50,
    url: '/products/organic-hemp-pads/',
    category: 'Periodcare',
    showcaseImage:
      'https://images.prismic.io/ecommerce-website/a613b553-b390-4af6-8ff2-9f58a4b523e5_home-product-desktop-pads.webp?auto=compress,format',
    showcaseImageMobile:
      'https://images.prismic.io/ecommerce-website/ba0cc453-c49c-4a4e-90b8-717b184f57bb_home-product-mobile-pads.webp?auto=compress,format'
  },
  {
    id: 'proviotics',
    title: 'ProViotics',
    description: 'Enrich your gut and vaginal microbiome',
    category: 'Supplements',
    color: Colors.babyBlue,
    url: '/products/proviotics/',
    showcaseImage:
      'https://images.prismic.io/ecommerce-website/111b06a3-3d1e-4fab-a2fb-fe95fe5616c1_proviotics_showcase_image.webp?auto=compress,format',
    showcaseImageMobile:
      'https://images.prismic.io/ecommerce-website/5dae2352-641f-4a8c-8c37-1b71c4624156_proviotics_showcase_mobile.webp?auto=compress,format'
  },
  {
    id: 'holiday_sampler',
    title: 'Holiday Sampler',
    subtitle: 'with scrunchie',
    category: 'Periodcare',
    url: '/festive/'
  },
  {
    id: 'sampler',
    title: 'Tampon Sampler',
    description: 'Not ready to subscribe? Try Daye tampons with no strings attached',
    color: Colors.paleGold,
    url: '/products/sampler/',
    category: 'Periodcare',
    showcaseImage:
      'https://images.prismic.io/ecommerce-website/6352f6a9-feb8-4c5f-843c-a27c495e595f_home-product-desktop-sampler.webp?auto=compress,format',
    showcaseImageMobile:
      'https://images.prismic.io/ecommerce-website/f3d75e86-c6ab-417c-a8d3-4d47268a08d6_home-product-mobile-sampler.webp?auto=compress,format'
  },
  {
    id: 'valentines_sampler',
    title: "Valentine's Sampler",
    subtitle: 'with scrunchie',
    category: 'Periodcare',
    url: '/valentines/'
  },
  {
    id: 'rose_candle',
    title: 'Vagina Candle',
    category: 'Gifts',
    url: '/products/your-vagina-smells-fine-candle/'
  },
  {
    id: 'valentines_box',
    title: "Valentine's Box",
    category: 'Periodcare',
    url: '/valentines/'
  },
  {
    id: 'blm_pin',
    title: 'BLM Pin',
    category: 'Other',
    url: '/products/blm-pin/'
  },
  {
    id: 'masks',
    title: 'Face Masks',
    description: 'Stay safe with our reusable organic cotton masks',
    category: 'Other',
    url: '/products/masks/',
    showcaseImage:
      'https://images.prismic.io/ecommerce-website/c4994579-bc19-4be4-abfc-54b73e4b47a3_home-product-desktop-masks.webp?auto=compress,format',
    showcaseImageMobile:
      'https://images.prismic.io/ecommerce-website/ed7d15c3-fb2a-43c6-9d8c-1186d4160f80_home-product-mobile-masks.webp?auto=compress,format',
    color: `${Colors.ecru}50`
  },
  {
    id: 'mask_light',
    title: 'Face Mask',
    subtitle: 'Cream Pink',
    category: 'Other',
    url: '/products/masks/'
  },
  {
    id: 'mask_dark',
    title: 'Face Mask',
    subtitle: 'Lilac Forest',
    category: 'Other',
    url: '/products/masks/'
  },
  {
    id: 'mask_blue',
    title: 'Face Mask',
    subtitle: 'Navy Sage',
    category: 'Other',
    url: '/products/masks/'
  },
  {
    id: 'mask_pink',
    title: 'Face Mask',
    subtitle: 'Gold Pink',
    category: 'Other',
    url: '/products/masks/'
  },
  {
    id: 'vagina_matters_book',
    title: 'Vagina Matters Book',
    category: 'Other',
    url: '/products/vagina-matters-book/'
  },
  {
    id: 'gift_card',
    title: 'Gift Card',
    category: 'Other',
    url: '/products/gift-card/'
  },
  {
    id: 'tin_case',
    title: 'Tampon Case',
    category: 'Other',
    url: '/products/tampon-case/'
  },
  {
    id: 'us_waitlist_prepaid_12',
    title: 'US Waitlist Prepaid Box (12)',
    category: 'Other'
  },
  {
    id: 'us_waitlist_prepaid_18',
    title: 'US Waitlist Prepaid Box (18)',
    category: 'Other'
  },
  {
    id: 'tampon_pouch',
    title: 'Tampon Pouch',
    category: 'Other'
  },
  {
    id: 'screening_kit',
    title: 'Diagnostic Tampon',
    description: 'Uncover gynae health risks using a non-invasive tampon sample',
    descriptionUS: 'Bye-bye swabs, stirrups, and speculums with our Vaginal Screening - Diagnostic Tampon.',
    url: '/products/at-home-vaginal-screening/',
    category: 'vaginal-care',
    showcaseImage:
      'https://images.prismic.io/ecommerce-website/2df980ac-38f8-4c59-9013-9f52c66e7095_home-product-desktop-vms.png?auto=compress,format',
    showcaseImageMobile:
      'https://images.prismic.io/ecommerce-website/77942e80-739a-4004-aa70-a08475d2eb93_showcase-diagnostic-tampon%402x.png?auto=compress,format',
    color: `linear-gradient(0deg, #D1E1EE 0%, #FAECE9 82.05%)`
  },
  {
    id: 'vms_essential',
    title: 'Microbiome Screening',
    description: 'Uncover gynae health risks using a non-invasive tampon sample',
    url: '/products/at-home-vaginal-screening/',
    category: 'vaginal-care',
    showcaseImage:
      'https://images.prismic.io/ecommerce-website/32504c93-8d47-41c7-bd44-e485d6c3d774_home-product-desktop-vms.webp?auto=compress,format',
    showcaseImageMobile:
      'https://images.prismic.io/ecommerce-website/68c68379-175e-4a2d-a341-777a93da902f_home-product-mobile-vms.webp?auto=compress,format',
    color: `${Colors.babyBlue}`
  },
  {
    id: 'vms_advanced',
    title: 'STI + Microbiome Screen',
    description: "Stay on top of your sexual health with Daye's non-invasive at-home diagnostic tampon",
    url: '/products/at-home-sti-screening/',
    category: 'vaginal-care',
    color: `${Colors.babyBlue}`
  },
  {
    id: 'sti_screening',
    title: 'STI Screening',
    url: '/products/at-home-sti-screening/',
    description: 'We are redefining STI testing',
    category: 'vaginal-care',
    color: `${Colors.powderPink}`,
    showcaseImage: 'https://images.prismic.io/ecommerce-website/4315a57f-c2a6-4449-a6c6-118591a04329_sti_screening%402x.png?auto=compress,format',
    showcaseImageMobile:
      'https://images.prismic.io/ecommerce-website/4315a57f-c2a6-4449-a6c6-118591a04329_sti_screening%402x.png?auto=compress,format'
  },
  {
    id: 'vms_medication_fee',
    title: 'Medication Fee',
    color: `${Colors.babyBlue}`
  },
  {
    id: 'vms_prescription_fee',
    title: 'Prescription Fee',
    color: `${Colors.babyBlue}`
  },
  {
    id: 'vms_essential_replacement',
    title: 'Screening Replacement Kit',
    category: 'Other'
  },
  {
    id: 'ppc_standard',
    title: 'Period Pain Clinic',
    url: '/period-pain-clinic',
    description: 'Get a personalized pain relief routine',
    subtitle: 'Standard',
    category: 'Other',
    showcaseImage:
      'https://images.prismic.io/ecommerce-website/0447e1d5-c740-47f4-8914-aa274d3dff08_home-product-desktop-ppc.png?auto=compress,format',
    showcaseImageMobile:
      'https://images.prismic.io/ecommerce-website/e32a6742-fc09-4bf6-8398-99b55a70d66d_showcase-ppc%402x.png?auto=compress,format',
    color: 'linear-gradient(180deg, #F1F6E4 0%, #E5F2E3 100%), #E5F2E3'
  },
  {
    id: 'ppc_advanced',
    title: 'Period Pain Clinic',
    category: 'Other',
    // TODO rethink if the includes field should be in the products list. It concerns only the PPC
    includes: ['Personalised report', '30-minute consultation']
  },
  {
    id: 'ppc_premium',
    title: 'Period Pain Clinic',
    subtitle: 'Premium package',
    category: 'Other',
    includes: ['Full period pain report', '20-minute pelvic pain specialist consultation', 'Pharmacist review fee']
  },
  {
    id: 'ppc_tampon_box',
    title: 'Prescription CBD Tampons',
    description: 'Organic and hyper-absorbent, the safest tampons in the world',
    color: Colors.mint,
    url: '/products/tampons/',
    category: 'Periodcare',
    showcaseImage:
      'https://images.prismic.io/ecommerce-website/0d4b50b2-9919-4e1e-bb02-64234268b520_home-product-desktop-tampons.webp?auto=compress,format',
    showcaseImageMobile:
      'https://images.prismic.io/ecommerce-website/7cb7a4c5-c3e8-410f-995c-3108303424a9_home-product-mobile-tampons.webp?auto=compress,format'
  },
  {
    id: 'consultation_sexual_health',
    title: '30-min expert nurse consultation',
    url: '/products/nurse-consultation/',
    category: 'Consultation',
    includes: ['30-minute consultation with a sexual health nurse']
  },
  {
    id: 'consultation_fertility',
    title: '30-min expert nurse consultation',
    category: 'Consultation',
    includes: ['30-minute consultation with a fertility specialist']
  },
  {
    id: 'consultation_nutritionist',
    title: 'Nutritionist consultation',
    category: 'Consultation',
    includes: ['45-min consultation with a nutritional therapist']
  },
  {
    id: 'consultation_general_practitioner',
    title: 'GP consultation',
    category: 'Consultation',
    includes: ['15-min consultation with a GP']
  },
  {
    id: 'consultation_pelvic_floor_therapist',
    title: 'Pelvic floor therapist consultation',
    category: 'Consultation',
    includes: ['45-min consultation with a pelvic floor specialist']
  },
  {
    id: 'festive_bundle',
    url: '/products/gift-bundle/',
    title: 'Gift Bundle'
  },
  {
    id: 'proviotics_vaginal_health',
    url: '/products/vaginal-health-bundle',
    title: 'Vaginal Health Bundle'
  }
];

export const getProductTitle = (productCode: string) => {
  const pricesData = getFromLocalStorage('productPrices', true);
  const product = pricesData && Array.isArray(pricesData) ? pricesData?.find((item) => item.id === productCode) : null;
  if (product && product.name) {
    return product.name;
  }
  const productData = productsData.find((p) => p.id === productCode);
  if (productData) return productData.title;

  return ProductCode[productCode as keyof typeof ProductCode];
};

export const getProductSubtitle = (productCode: string) => {
  const productData = productsData.find((product) => product.id === productCode);
  if (productData) return productData.subtitle;
  return '';
};

export const getListOfIncludesForProduct = (productCode: string) => {
  const productData = productsData.find((product) => product.id === productCode);
  if (productData) return productData.includes ?? [];
  return [];
};

export const getProductLink = (productCode: string) => {
  const productData = productsData.find((product) => product.id === productCode);
  if (productData) return productData.url || '';
  return '';
};

export const getProductCategory = (productCode: string) => {
  const productData = productsData.find((product) => product.id === productCode);
  if (productData) return productData.category;
  return '';
};
