import { CountryCode, Variant } from '@dayetopia/types';
import { getProductPrices } from '@components/Context';
import useBoxPrice from '@components/useBoxPrice';
import { CurrencyObj, ProductCode, ProductPrice } from '@contracts';
import { getFromLocalStorage } from '@utils/localStorage';
import { getSubscriptionPlans } from './getSubscriptionPlans';
import { isPadsStackBox } from './pads';
import { getDiscountPercent } from './subscriptions';

export const roundNumber = (number: number) => Math.round(number * 10) / 10;

export const getNewProductPrice = async (currency: CurrencyObj, country: CountryCode, productId: string, subscriptionPlan?: string) => {
  const pricesData = await getProductPrices();

  if (pricesData && Array.isArray(pricesData)) {
    const product = pricesData.find((item: { id: string }) => item.id === productId);

    if (product) {
      // Calculate VAT percentage
      const vatPercent = product.vat ? product.vat[country] ?? 0 : 0;

      let priceItem;
      if (product.pricePerPlan && subscriptionPlan) {
        const priceForPlan = product.pricePerPlan.find((plan: { planType: string }) => plan.planType === subscriptionPlan);
        priceItem = priceForPlan?.price.find((item: { amount: number; currency: string }) => item.currency === currency.letterCode);
      } else {
        priceItem = product.price?.find((item: { amount: number; currency: string }) => item.currency === currency.letterCode);
      }

      if (priceItem) {
        const vatAmount = priceItem.amount * (vatPercent / 100);
        return Number((priceItem.amount + vatAmount).toFixed(2));
      }
    }
  }

  return -1;
};

export const getMinPrice = async (productCode: ProductCode, currency: CurrencyObj, country: CountryCode, variants?: Variant) => {
  try {
    let maxDiscount = 0;
    let basePrice;
    let startingFrom = false;
    const plansProductCode = productCode === ProductCode.VMSEssential ? ProductCode.ScreeningKit : productCode;

    if (!plansProductCode) {
      return;
    }

    const plans = await getSubscriptionPlans(plansProductCode);
    if (plans) {
      maxDiscount = plans.reduce((max: number, subscription) => Math.max(max, getDiscountPercent(subscription, country)), 0);
    }

    let finalVariants: Variant | undefined = variants;

    if (productCode === ProductCode.TamponBox) {
      if (!finalVariants) {
        if (country === CountryCode.US) {
          finalVariants = { nakedregular: 18 } as Variant;
        } else {
          finalVariants = { nakedregular: 12 } as Variant;
        }
      }
      basePrice = getDiscountedPrice((await useBoxPrice(finalVariants, currency, country)) ?? -1, maxDiscount);
      startingFrom = true;
    } else if (productCode === ProductCode.Pads) {
      if (isPadsStackBox(country)) {
        basePrice = getDiscountedPrice((await getProductPrice(ProductCode.PadsBox12R, currency, country))?.price ?? -1, maxDiscount);
        startingFrom = true;
      } else {
        basePrice = await getNewProductPrice(currency, country, ProductCode.PadsBox16SP);
        startingFrom = true;
      }
    } else {
      basePrice = (await getProductPrice(productCode, currency, country)).price;
    }

    return {
      startingFrom,
      price: Number(basePrice?.toFixed(2))
    };
  } catch (e) {
    console.error(e);
  }
};

const getDiscountedPrice = (originalPrice: number, discountPercentage: number): number => {
  const itemPrice = originalPrice - (originalPrice * discountPercentage) / 100;
  return Math.round(itemPrice * 20) / 20;
};

export default async function getProductPrice(productId: string, currency: CurrencyObj, country: string) {
  const productCode = productId.includes('mask') ? 'mask' : productId;
  const pricesData = await getProductPrices();

  if (pricesData) {
    const product = pricesData.find((itm) => itm.id === productCode);
    const firstVariant = product?.variants?.[0];
    const productPrice = product?.price || firstVariant?.price;

    if (productPrice) {
      const vatPercent = product?.vat[country] ?? 0;
      const price = productPrice.find((p) => p.currency === currency.letterCode);

      if (price) {
        const vatAmount = price.amount * (vatPercent / 100);
        const finalPrice = price.amount + vatAmount;
        return { price: Number(finalPrice.toFixed(2)) };
      }
    }
  }
  return { price: 0 };
}

export const fragmentPrice = (price: number) => price.toFixed(2).split('.');

export const getPoundsFromPrice = (price: number) => fragmentPrice(price)[0];

export const getCoinsFromPrice = (price: number) => fragmentPrice(price)[1];

export function getProductPricesFromStorage() {
  const pricesData = getFromLocalStorage('productPrices', true) || [];

  return pricesData as ProductPrice[];
}
