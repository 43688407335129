import { CountryCode, Variants } from '@dayetopia/types';
import { PAD_STACK_COUNTS, PAD_STACK_PRODUCT_IDS } from '@constants';
import { BoxItem, CartItem, ProductCode, BoxState, PadSizeType, TamponBoxType } from '@contracts';
import { ProductData as ShopProductData } from '@data/storefront';
import { showInCountryCondition } from './helpers';
import { getAllPadTypes, isPadsAndStackBox, isPadsStackBox } from './pads';

export const filterProducts = (country: CountryCode, products: ShopProductData[]) =>
  products.filter((product) => {
    return showInCountryCondition(product.show_in_countries?.text, country);
  });

/**
 * Filters an array of products based on the country and location.
 *
 * @param {ProductCode[]} products - The array of product codes to filter.
 * @param {CountryCode} country - The country code used for filtering.
 * @returns {ProductCode[]} The filtered array of product codes.
 */
export const filterProductsByCountry = (products: ProductCode[], country: CountryCode) => {
  return products.filter((product) => {
    if (product === ProductCode.TamponBox && country === CountryCode.PT) {
      return false;
    }
    return true;
  });
};

export const applyCountrySpecificSorting = (country: CountryCode, products: ShopProductData[]) => {
  const getSortingNum = (product: ShopProductData) => {
    if (product?.country_sorting?.text && product?.country_sorting.text.length > 0) {
      const sort = product?.country_sorting.text.split(',').filter((el) => country === el?.split(':')[0]);
      if (sort?.length > 0) {
        const order = sort[0].split(':');
        if (order.length > 0) return Number(order[1]);
      }
    }
    return Number.MAX_SAFE_INTEGER;
  };

  const countrySpecificSorting = (a: ShopProductData, b: ShopProductData) => {
    if (getSortingNum(a) < getSortingNum(b)) {
      return -1;
    }
    if (getSortingNum(a) > getSortingNum(b)) {
      return 1;
    }
    return 0;
  };

  return products.sort(countrySpecificSorting);
};

export const getTamponConfigAndCounts = (configString: string) => {
  const boxConfig = configString.split('/');

  const config = {
    cbdregular: Number(boxConfig[0]),
    cbdsuper: Number(boxConfig[1]),
    nakedregular: Number(boxConfig[2]),
    nakedsuper: Number(boxConfig[3])
  };

  const count = Object.values(config).reduce((t, value) => t + value, 0);
  return { config, count };
};

export const getTamponValueString = (config) => {
  const cbdRegulars = config.cbdregular ? `${config.cbdregular ?? 0} CBD Regular + ` : '';
  const cbdSupers = config.cbdsuper ? `${config.cbdsuper ?? 0} CBD Super + ` : '';
  const nakedRegulars = config.nakedregular ? `${config.nakedregular ?? 0} Nude Regular + ` : '';
  const nakedSupers = config.nakedsuper ? `${config.nakedsuper ?? 0} Nude Super` : '';
  let boxContains = `${cbdRegulars}${cbdSupers}${nakedRegulars}${nakedSupers}`;
  if (boxContains.endsWith(' + ')) {
    boxContains = boxContains.slice(0, -3);
  }
  const box = {
    label: `Custom (${boxContains})`,
    value: `${config.cbdregular ?? 0}/${config.cbdsuper ?? 0}/${config.nakedregular ?? 0}/${config.nakedsuper ?? 0}`,
    custom: true
  };
  return box;
};

export const getPadValueString = (config) => {
  const box = {
    label: '',
    value: `${config.pr ?? 0}/${config.pn ?? 0}/${config.pnp ?? 0}/${config.pl ?? 0}`
  };
  return box;
};

export const getPadConfigAndCounts = (configString: string) => {
  const boxConfig = configString.split('/');

  const config = {
    pr: Number(boxConfig[0] ?? 0),
    pn: Number(boxConfig[1] ?? 0),
    pnp: Number(boxConfig[2] ?? 0),
    pl: Number(boxConfig[3] ?? 0)
  };

  const count = Object.values(config).reduce((t, value) => t + value, 0);
  return { config, count };
};

export const getCurrentTamponsCount = (boxVariants) => {
  if (!boxVariants) return 0;
  const tamponIds = ['nakedregular', 'nakedsuper', 'cbdregular', 'cbdsuper'];
  const variantsArray = Object.entries(boxVariants);
  const tampons = Object.fromEntries(variantsArray.filter(([key]) => tamponIds.some((tamponId) => tamponId === key)));
  const tamponsCount = Object.values(tampons).length > 0 ? Object.values(tampons).reduce((total, count) => total + count) : 0;
  return Number(tamponsCount) ?? 0;
};

export const getCurrentPadsCount = (boxVariants: Variants, country: CountryCode) => {
  const isStackBox = isPadsStackBox(country);

  if (isStackBox) {
    return getCurrentStackPadsCount(boxVariants);
  }

  return getCurrentLegacyPadsCount(boxVariants);
};

export const getMinPadsCount = (boxVariants: Record<string, number>, country: CountryCode) => {
  const isStackBox = isPadsStackBox(country);

  if (isStackBox) {
    return getCurrentStackPadsCount(boxVariants);
  }

  return getCurrentLegacyPadsCount(boxVariants);
};

export const getTamponBoxData = (country: CountryCode) => {
  const isStackBox = isPadsStackBox(country);
  const tamponBoxData = [
    {
      type: TamponBoxType.Tampons12,
      tamponsCount: 12,
      padsCount: 0
    },
    {
      type: TamponBoxType.Tampons18,
      tamponsCount: 18,
      padsCount: 0
    },
    {
      type: TamponBoxType.TamponsPads12,
      tamponsCount: 12,
      padsCount: isStackBox ? 1 : 8
    },
    {
      type: TamponBoxType.TamponsPads18,
      tamponsCount: 18,
      padsCount: isStackBox ? 1 : 8
    }
  ];

  return tamponBoxData;
};

export const getCurrentLegacyPadsCount = (boxVariants: Variants) => {
  if (!boxVariants) return 0;
  const allPadTypes = getAllPadTypes();
  const padIds = [...allPadTypes];
  const variantsArray = Object.entries(boxVariants);
  const pads = Object.fromEntries(variantsArray.filter(([key]) => padIds.some((padId) => padId === key)));
  const padsCount = calculatePadsCount(pads);

  return padsCount;
};

export const getCurrentStackPadsCount = (boxVariants: Variants) => {
  if (!boxVariants) return 0;
  const allPadTypes = getAllPadTypes();
  const padIds = allPadTypes.map((pad) => PAD_STACK_PRODUCT_IDS[pad]);
  const variantsArray = Object.entries(boxVariants);
  const pads = Object.fromEntries(variantsArray.filter(([key]) => padIds.some((padId) => padId === key)));
  const padsCount = calculatePadsCount(pads);

  return padsCount;
};

/**
 * Calculates the total count of pads based on the provided pads configuration.
 *
 * @param {Record<string, number>} pads - An object where keys are pad types and values are their respective counts.
 * @returns {number} The total count of pads.
 */
export function calculatePadsCount(pads: Record<string, number>): number {
  return (
    Number(
      Object.entries(pads).length > 0
        ? Object.entries(pads).reduce((total, [key, count]: any) => {
            const padCount = pads[PAD_STACK_PRODUCT_IDS[key as PadSizeType]] ? count * PAD_STACK_COUNTS[key as PadSizeType] : count;
            return total + padCount;
          }, 0)
        : 0
    ) ?? 0
  );
}

/**
 * Checks if any pad variants are selected in the box state.
 * This function avoids shadowing by not re-declaring variable names from the upper scope.
 *
 * @param {Pick<BoxState, 'pr' | 'pn' | 'pnp' | 'pl'>} padsState - An object containing the counts for pad variants.
 * @returns {boolean} True if any pad variant is selected, otherwise false.
 */
export const arePadsSelected = ({ pr, pn, pnp, pl }: Pick<BoxState, 'pr' | 'pn' | 'pnp' | 'pl'>): boolean => {
  return pr > 0 || pn > 0 || pnp > 0 || pl > 0;
};

/**
 * Builds the purchase item configuration for both subscription boxes and default configurations.
 * It now also handles the logic for determining the variant and adding a default configuration
 * if no specific box items are provided.
 * @param {BoxItem[]} boxItems - The box items to build configurations for.
 * @param {boolean} isSubscription - Flag indicating if the configuration is for a subscription.
 * @param {string} productCode - The product code to derive the variant from, if needed.
 * @return {PurchaseItemConfiguration[]} The constructed configurations.
 */
export function buildPurchaseItemConfiguration(boxItems: BoxItem[], isSubscription: boolean, productCode: ProductCode, country: CountryCode) {
  const configuration = [];
  const variant = getBaseVariantOnDemand(productCode);
  const multiMonthVariantCount = 3; // Ideally, retrieve this dynamically.

  if (boxItems && boxItems.length > 0) {
    boxItems.forEach((boxItem) => {
      const isStackBox = isPadsAndStackBox(productCode, country);

      const purchaseItemConfiguration = {
        variant: isStackBox ? PAD_STACK_PRODUCT_IDS[boxItem.name as unknown as keyof typeof PAD_STACK_PRODUCT_IDS] : boxItem.name,
        count: boxItem.count
      };

      if (!isSubscription) {
        const itemPrice = boxItem.count * (boxItem.price ?? 0);
        purchaseItemConfiguration.actualPrice = parseFloat(itemPrice.toFixed(2));
      }

      configuration.push(purchaseItemConfiguration);
    });
  } else {
    // Add default configuration when no specific box items are provided.
    configuration.push({ variant, count: multiMonthVariantCount });
  }

  return configuration;
}

/**
 * Retrieves the base variant for a given product code, specifically handling cases where
 * certain product codes require further processing. If the product code matches any
 * predefined special cases, it calls `getBaseVariant` to process it further.
 * Otherwise, it returns the product code as is.
 *
 * @param {ProductCode} productCode - The product code to process.
 * @return {ProductCode} The base variant of the product or the original product code.
 */
export function getBaseVariantOnDemand(productCode: ProductCode) {
  if (productCode === ProductCode.ProvioticsMultiMonth) {
    return getBaseVariant(productCode);
  }

  return productCode;
}

/**
 * Extracts the base variant from a product code. If the product is a multi-month subscription,
 * indicated by the '_mm' suffix, it removes this suffix to normalize the variant name.
 * @param {string} productCode - The product code to process.
 * @return {string} The base variant of the product.
 */
export function getBaseVariant(productCode: ProductCode) {
  const isMultiMonth = productCode.endsWith('_mm');
  return isMultiMonth ? productCode.replace(/_mm$/, '') : productCode;
}

/**
 * Calculates the price per quantity for a given cart item. It ensures the price is presented
 * as a number with two decimal places, following standard financial practices.
 * @param {SubscriptionCartItem} cartItem - The cart item to calculate the price for.
 * @return {number} The price per quantity as a numeric value.
 */
export function calculatePricePerQuantity(cartItem: CartItem) {
  const pricePerQuantity = Number(cartItem.price) / Number(cartItem.quantity);
  return Number(pricePerQuantity.toFixed(2));
}

/**
 * Converts a BoxState object to an array of BoxItem objects.
 * This function filters out any items with a count of 0.
 *
 * @param {BoxState} boxState - The BoxState object containing tampon counts.
 * @returns {BoxItem[]} An array of BoxItem objects representing the non-zero tampon counts.
 */
export function convertBoxStateToBoxItems(boxState: BoxState): BoxItem[] {
  const boxItems = [
    { name: 'CBD Regular', key: 'cbd-regular', count: boxState.cbdregular },
    { name: 'CBD Super', key: 'cbd-super', count: boxState.cbdsuper },
    { name: 'Nude Regular', key: 'naked-regular', count: boxState.nakedregular },
    { name: 'Nude Super', key: 'naked-super', count: boxState.nakedsuper }
  ];

  return boxItems.filter((item) => item.count > 0);
}

/**
 * Checks if the product code override is a preset tampon box configuration.
 *
 * @param {string} productCodeOverride - The product code override to check.
 * @returns {boolean} True if the product code override is a preset tampon box configuration, otherwise false.
 */
export function getIsPresetTamponBox(productCodeOverride?: string) {
  return productCodeOverride && productCodeOverride.includes('tampon_box_') && productCodeOverride.includes('/');
}

/**
 * Retrieves the tracking ID for a product.
 *
 * @param {ProductData} data - The product data to retrieve the tracking ID from.
 * @returns {string} The tracking ID for the product.
 */
export function getTrackingId(data: ShopProductData) {
  return data.product_code ?? data.product_title.text;
}
