import { CountryCode, ProductCodeKey, Subscription, SubscriptionAddOn, SubscriptionPlan, Variants } from '@dayetopia/types';
import checkResStatus from '@utils/checkResStatus';
import getOmsUrl from '@utils/getOmsUrl';
import { getAllPadTypes, isPadsStackBox } from './pads';
import { getCurrentTamponsCount, getCurrentPadsCount } from './product';

export const getPlanPrice = (
  price: number,
  subscription: SubscriptionPlan,
  country: CountryCode,
  shippingPrice?: number,
  calculateBillingPrice?: boolean
) => {
  const billingPrice = calculateBillingPrice ? getBillingPriceForSubscription(subscription, price, country) : price;

  return calculateBillingPrice ? billingPrice + (shippingPrice ?? 0) : billingPrice;
};

export const getBillingPriceForSubscription = (subscription: SubscriptionPlan, boxPrice: number, country: CountryCode) => {
  const discountPercent = getDiscountPercent(subscription, country);
  const itemPrice = boxPrice - (boxPrice * discountPercent) / 100;
  const discountedPrice = subscription.productCode === ProductCodeKey.Pads ? (itemPrice * 20) / 20 : Math.round(itemPrice * 20) / 20;

  return (discountedPrice * subscription.subscriptionInterval.length) / subscription.billing.installments;
};

export const getSubscriptionPlans = async (productCode: string) => {
  const omsUrl = getOmsUrl();
  const url = `${omsUrl}/ecommerce/subscriptions/${productCode}`;
  const options = {
    method: 'GET'
  };

  try {
    const res = await fetch(url, options);
    const data = checkResStatus(res);
    return await data;
  } catch (err) {
    console.debug('Error: ', JSON.stringify(err));
    throw err;
  }
};

const convertAddOnsToVariants = (items: SubscriptionAddOn[]) => {
  const addOnVariants: Variants = {};
  items.forEach((item: SubscriptionAddOn) => {
    addOnVariants[item.addOn.code] = item.quantity;
  });
  return addOnVariants;
};

export const getDiscountPercent = (subscription: SubscriptionPlan, country: CountryCode) => {
  return subscription?.discountPercent?.[country] ?? subscription?.discountPercent?.default ?? 0;
};

export const getVariantsFromSubscription = (subscription: Subscription, isPadSubscription: boolean, country: CountryCode) => {
  const { variants, addOns, pendingChange } = subscription;

  const pendingAddOns = pendingChange && pendingChange.addOns ? pendingChange.addOns : addOns;
  const pendingVariants =
    pendingChange && pendingChange.customFields ? pendingChange.customFields.find((field: any) => field.name === 'variants') : null;
  let returnVariants: Variants = {};

  if (pendingAddOns && pendingAddOns.length > 0) {
    returnVariants = convertAddOnsToVariants(pendingAddOns);
  } else if (pendingVariants || variants) {
    returnVariants = pendingVariants ? JSON.parse(pendingVariants.value) : variants;
  }

  if (isPadsStackBox(country)) {
    const nonStackBoxVariants = getAllPadTypes();

    nonStackBoxVariants.forEach((variant) => {
      delete returnVariants[variant];
    });
  }

  if (returnVariants) {
    returnVariants.boxSize = isPadSubscription ? getCurrentPadsCount(returnVariants, country) : getCurrentTamponsCount(returnVariants);
  }

  return returnVariants;
};
