import { CartItem } from '@contracts';
import { getFromLocalStorage } from '@utils/localStorage';
import { calculateTotalPrice } from './cart';

export const setOnSessionStorage = (key: string, value: any) => {
  const json = JSON.stringify(value);

  try {
    sessionStorage.setItem(key, json);
  } catch (e) {
    // sessionStorage is not accessible, use localStorage instead
    try {
      if (typeof window !== 'undefined') {
        localStorage.setItem(key, json);
      }
    } catch (err) {
      // localStorage is also not accessible, handle the error as needed
      console.error('Both sessionStorage and localStorage are not accessible.');
    }
  }
};

export const getFromStorage = (key: string, returnJson?: boolean) => {
  let value: string | null = null;

  try {
    value = sessionStorage.getItem(key);
  } catch (e) {
    // sessionStorage is not accessible, try to use localStorage instead
    try {
      if (typeof window !== 'undefined') {
        value = localStorage.getItem(key);
      }
    } catch (err) {
      // localStorage is also not accessible, handle the error as needed
      console.error('Both sessionStorage and localStorage are not accessible.');
    }
  }

  let returnValue = value;
  if (returnJson && value && value !== 'undefined') {
    try {
      returnValue = JSON.parse(value);
    } catch (e) {
      console.error('err', e);
      returnValue = value;
    }
  }

  return returnValue;
};

export const removeFromStorage = (key: string): void => {
  try {
    sessionStorage.removeItem(key);
  } catch (e) {
    // sessionStorage is not accessible, use localStorage instead
    try {
      if (typeof window !== 'undefined') {
        localStorage.removeItem(key);
      }
    } catch (err) {
      // localStorage is also not accessible, handle the error as needed
      console.error('Both sessionStorage and localStorage are not accessible.');
    }
  }
};

export function hydrateInitialState(initialState: any, excludeKeys: string[] = []) {
  const newState = { ...initialState };
  Object.keys(newState).forEach((key) => {
    if (excludeKeys.includes(key)) {
      return;
    }

    let value = getFromStorage(key, false) ?? getFromLocalStorage(key, true) ?? null;

    if (value && value !== 'undefined') {
      try {
        // If the value is already an array (like in the case of cartItems), use it directly
        if (Array.isArray(value)) {
          newState[key] = value;
        } else {
          value = JSON.parse(value);
          newState[key] = value;
        }
      } catch (e) {
        // If parsing fails but the value looks like it's already the correct format, use it directly
        if (key === 'cartItems' && Array.isArray(value)) {
          newState[key] = value;
        } else {
          newState[key] = value;
        }
      }
    }
  });

  if (!Array.isArray(newState.cartItems)) {
    newState.cartItems = [];
  } else {
    newState.cartItems = newState.cartItems.filter((product: CartItem) => product.productCode);
    const totalPrice = calculateTotalPrice(newState.cartItems);
    newState.price = totalPrice;
  }

  return newState;
}

export function clearSessionStorage(initialState: any, persistOnSession: string[]) {
  Object.keys(initialState).forEach((key) => {
    if (persistOnSession.indexOf(key) === -1) {
      removeFromStorage(key);
    }
  });
}
