import { useContext, useEffect, useReducer } from 'react';
import { Context } from '@components/Context';
import { hydrateInitialState, setOnSessionStorage } from '@utils/sessionStorage';

export const CBD_REGULAR_TAMPON_KEY = 'cbd-regular';
export const CBD_SUPER_TAMPON_KEY = 'cbd-super';
export const NAKED_REGULAR_TAMPON_KEY = 'naked-regular';
export const NAKED_SUPER_TAMPON_KEY = 'naked-super';

export const CBD_REGULAR_TAMPON_NAME = 'cbdregular';
export const CBD_SUPER_TAMPON_NAME = 'cbdsuper';
export const NAKED_REGULAR_TAMPON_NAME = 'nakedregular';
export const NAKED_SUPER_TAMPON_NAME = 'nakedsuper';

export const REGULAR_PAD_KEY = 'pr';
export const NIGHT_PAD_KEY = 'pn';
export const NIGHT_PLUS_PAD_KEY = 'pnp';
export const LINER_PAD_KEY = 'pl';

export const REGULAR_PAD_NAME = 'Regular';
export const NIGHT_PAD_NAME = 'Night';
export const NIGHT_PLUS_PAD_NAME = 'Night+';

export const LINER_PAD_PRICE_DEFAULT = 0.25;

const DEFAULT_CBD_REGULAR_TAMPON_COUNT = 0;
const DEFAULT_CBD_SUPER_TAMPON_COUNT = 3;
const DEFAULT_NAKED_REGULAR_TAMPON_COUNT = 7;
const DEFAULT_NAKED_SUPER_TAMPON_COUNT = 2;

export const initialState: BoxState = {
  boxSize: 12,
  cbdsuper12: DEFAULT_CBD_SUPER_TAMPON_COUNT,
  cbdregular12: DEFAULT_CBD_REGULAR_TAMPON_COUNT,
  nakedsuper12: DEFAULT_NAKED_SUPER_TAMPON_COUNT,
  nakedregular12: DEFAULT_NAKED_REGULAR_TAMPON_COUNT,
  cbdsuper18: 6,
  cbdregular18: 0,
  nakedsuper18: 2,
  nakedregular18: 10,
  boxPrice: 0,
  rehydrated: 0,
  mask: 0,
  proviotics: 0
};

function reducer(state: BoxState, action: BoxActionTypes): BoxState {
  switch (action.type) {
    case 'setBoxSize':
      return {
        ...state,
        boxSize: action.data
      };
    case 'setCbdSuper12':
      setOnSessionStorage('cbdsuper12', action.data);
      return {
        ...state,
        cbdsuper12: action.data
      };
    case 'setCbdRegular12':
      setOnSessionStorage('cbdregular12', action.data);
      return {
        ...state,
        cbdregular12: action.data
      };
    case 'setNakedSuper12':
      setOnSessionStorage('nakedsuper12', action.data);
      return {
        ...state,
        nakedsuper12: action.data
      };
    case 'setNakedRegular12':
      setOnSessionStorage('nakedregular12', action.data);
      return {
        ...state,
        nakedregular12: action.data
      };
    case 'setCbdSuper18':
      setOnSessionStorage('cbdsuper18', action.data);
      return {
        ...state,
        cbdsuper18: action.data
      };
    case 'setCbdRegular18':
      setOnSessionStorage('cbdregular18', action.data);
      return {
        ...state,
        cbdregular18: action.data
      };
    case 'setNakedSuper18':
      setOnSessionStorage('nakedsuper18', action.data);
      return {
        ...state,
        nakedsuper18: action.data
      };
    case 'setNakedRegular18':
      setOnSessionStorage('nakedregular18', action.data);
      return {
        ...state,
        nakedregular18: action.data
      };
    case 'setMask':
      setOnSessionStorage('mask', action.data);
      return {
        ...state,
        mask: action.data
      };
    case 'setProviotics':
      setOnSessionStorage('proviotics', action.data);
      return {
        ...state,
        proviotics: action.data
      };
    case 'setBoxPrice':
      return {
        ...state,
        boxPrice: action.data
      };
    case 'REHYDRATE-FROM-SESSION-STORAGE':
      return {
        ...action.data,
        rehydrated: true
      };
    case 'INITIALISE-BOX':
      setOnSessionStorage(`cbdsuper12`, action.data.cbdsuper12);
      setOnSessionStorage('cbdregular12', action.data.cbdregular12);
      setOnSessionStorage('nakedsuper12', action.data.nakedsuper12);
      setOnSessionStorage('nakedregular12', action.data.nakedregular12);
      setOnSessionStorage('cbdsuper18', action.data.cbdsuper18);
      setOnSessionStorage('cbdregular18', action.data.cbdregular18);
      setOnSessionStorage('nakedsuper18', action.data.nakedsuper18);
      setOnSessionStorage('nakedregular18', action.data.nakedregular18);
      setOnSessionStorage('mask', action.data.mask);
      setOnSessionStorage('proviotics', action.data.proviotics);
      return {
        ...state,
        cbdsuper12: action.data.cbdsuper12 || 0,
        cbdregular12: action.data.cbdregular12 || 0,
        nakedsuper12: action.data.nakedsuper12 || 0,
        nakedregular12: action.data.nakedregular12 || 0,
        cbdsuper18: action.data.cbdsuper18 || 0,
        cbdregular18: action.data.cbdregular18 || 0,
        nakedsuper18: action.data.nakedsuper18 || 0,
        nakedregular18: action.data.nakedregular18 || 0,
        mask: action.data.mask || 0,
        proviotics: action.data.proviotics || 0
      };
    default:
      throw new Error(JSON.stringify(action, null, 2));
  }
}

export default function useBoxBuilderState() {
  const { state } = useContext(Context);
  const { recommendedBox } = state;
  const [boxState, boxDispatch] = useReducer(reducer, initialState);

  const {
    rehydrated,
    boxSize,
    boxPrice,
    cbdsuper12,
    cbdregular12,
    nakedsuper12,
    nakedregular12,
    cbdsuper18,
    cbdregular18,
    nakedsuper18,
    nakedregular18,
    mask,
    proviotics
  } = boxState;

  // hydrate from session storage
  useEffect(() => {
    const initialStateWithSessionStorage = hydrateInitialState(initialState);
    boxDispatch({
      type: 'REHYDRATE-FROM-SESSION-STORAGE',
      data: initialStateWithSessionStorage
    });
  }, []);

  // Check if user has made changes to box. If no changes, then hydrate with either:
  // 1) On account page: The user's next box.
  // 2) On box page: With the recommended box.
  useEffect(() => {
    const sumTampons = cbdsuper12 + cbdregular12 + nakedsuper12 + nakedregular12 + cbdsuper18 + cbdregular18 + nakedsuper18 + nakedregular18;
    const hasCustomisedBox = sumTampons > 0;

    if (!rehydrated) return;

    if (hasCustomisedBox) return;

    if (recommendedBox) {
      boxDispatch({
        type: 'INITIALISE-BOX',
        data: recommendedBox
      });
    }
  }, [recommendedBox, rehydrated]);

  const [cbdsuper, cbdregular, nakedsuper, nakedregular] = [
    boxState[`cbdsuper${boxSize}`],
    boxState[`cbdregular${boxSize}`],
    boxState[`nakedsuper${boxSize}`],
    boxState[`nakedregular${boxSize}`]
  ];

  const tamponCount = cbdsuper + cbdregular + nakedsuper + nakedregular;
  const fullBox = tamponCount === boxSize;

  const derivedBoxState: DerivedBoxState = {
    boxSize,
    cbdsuper,
    cbdregular,
    nakedsuper,
    nakedregular,
    tamponCount,
    fullBox,
    boxPrice,
    boxVariants: {
      cbdsuper,
      cbdregular,
      nakedsuper,
      nakedregular,
      mask,
      proviotics
    },
    boxItems: [
      { count: cbdsuper, key: CBD_SUPER_TAMPON_KEY, name: CBD_SUPER_TAMPON_NAME },
      { count: cbdregular, key: CBD_REGULAR_TAMPON_KEY, name: CBD_REGULAR_TAMPON_NAME },
      { count: nakedsuper, key: NAKED_SUPER_TAMPON_KEY, name: NAKED_SUPER_TAMPON_NAME },
      { count: nakedregular, key: NAKED_REGULAR_TAMPON_KEY, name: NAKED_REGULAR_TAMPON_NAME }
    ]
  };

  return { derivedBoxState, boxDispatch };
}

export interface DerivedBoxState {
  boxSize: number;
  cbdsuper: number;
  cbdregular: number;
  nakedsuper: number;
  nakedregular: number;
  tamponCount: number;
  fullBox: boolean;
  boxPrice: number;
  boxVariants: {
    cbdsuper: number;
    cbdregular: number;
    nakedsuper: number;
    nakedregular: number;
    mask: number;
    proviotics: number;
  };
  boxItems: { name: string; count: number; key?: string }[];
}

interface BoxState {
  boxSize: number;
  cbdsuper12: number;
  cbdregular12: number;
  nakedsuper12: number;
  nakedregular12: number;
  cbdsuper18: number;
  cbdregular18: number;
  nakedsuper18: number;
  nakedregular18: number;
  boxPrice: number;
  rehydrated: number;
  [key: string]: number;
}

interface SetBoxSize {
  type: string;
  data: number;
}

interface SetCbdSuper12 {
  type: string;
  data: number;
}

interface SetCbdRegular12 {
  type: string;
  data: number;
}

interface SetNakedSuper12 {
  type: string;
  data: number;
}

interface SetNakedregular12 {
  type: string;
  data: number;
}

interface SetCbdsuper18 {
  type: string;
  data: number;
}

interface SetCbdregular18 {
  type: string;
  data: number;
}

interface SetNakedsuper18 {
  type: string;
  data: number;
}

interface SetNakedregular18 {
  type: string;
  data: number;
}

interface SetBoxPrice {
  type: string;
  data: number;
}

interface SetMask {
  type: string;
  data: number;
}

interface SetProviotics {
  type: string;
  data: number;
}

interface INITIALISEBOX {
  type: string;
  data: any;
}

export type BoxActionTypes =
  | SetBoxSize
  | SetCbdSuper12
  | SetCbdRegular12
  | SetNakedSuper12
  | SetNakedregular12
  | SetCbdsuper18
  | SetCbdregular18
  | SetNakedsuper18
  | SetNakedregular18
  | SetBoxPrice
  | SetMask
  | SetProviotics
  | INITIALISEBOX;
