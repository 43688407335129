import { CountryCode, SubscriptionPlan, Variant } from '@dayetopia/types';
import {
  CBD_REGULAR_TAMPON_KEY,
  CBD_REGULAR_TAMPON_NAME,
  CBD_SUPER_TAMPON_KEY,
  CBD_SUPER_TAMPON_NAME,
  LINER_PAD_KEY,
  LINER_PAD_PRICE_DEFAULT,
  NAKED_REGULAR_TAMPON_KEY,
  NAKED_REGULAR_TAMPON_NAME,
  NAKED_SUPER_TAMPON_KEY,
  NAKED_SUPER_TAMPON_NAME,
  NIGHT_PAD_KEY,
  NIGHT_PLUS_PAD_KEY,
  REGULAR_PAD_KEY
} from '@components/tampons/state/useBoxState';
import useBoxPrice from '@components/useBoxPrice';
import { PAD_STACK_PRODUCT_IDS } from '@constants';
import {
  BoxState,
  CartItem,
  CartItemType,
  Prices,
  ProductCode,
  BoxItem,
  CurrencyObj,
  PadsBoxState,
  PadsBoxStackState,
  PadSizeType,
  CartItems,
  PromotionObject,
  CartItemGiftType
} from '@contracts';
import { getProductTitle } from '@data/products';
import { isPromotionActive } from './contextHelpers';
import { isHsaFsaAvailable } from './flexHsaFsa';
import { getFromLocalStorage } from './localStorage';
import { convertPadsStackBoxItems, isBoxStatePadsStackBox, isPadsAndStackBox } from './pads';

const getBoxItems = (boxState: BoxState, prices?: Prices) => {
  if (!boxState) return [];
  if (!prices) return Object.keys(boxState).map((key) => ({ count: boxState[key], name: key }));

  const { cbdsuper, cbdregular, nakedsuper, nakedregular } = boxState;
  const result = [
    { count: cbdsuper, key: CBD_SUPER_TAMPON_KEY, name: CBD_SUPER_TAMPON_NAME, price: prices.cbdTamponPrice },
    { count: cbdregular, key: CBD_REGULAR_TAMPON_KEY, name: CBD_REGULAR_TAMPON_NAME, price: prices.cbdTamponPrice },
    { count: nakedsuper, key: NAKED_SUPER_TAMPON_KEY, name: NAKED_SUPER_TAMPON_NAME, price: prices.nakedTamponPrice },
    { count: nakedregular, key: NAKED_REGULAR_TAMPON_KEY, name: NAKED_REGULAR_TAMPON_NAME, price: prices.nakedTamponPrice }
  ];

  const pr = boxState?.pr || 0;
  const pn = boxState?.pn || 0;
  const pnp = boxState?.pnp || 0;
  const pl = boxState?.pl || 0;

  if (pr > 0) result.push({ count: pr, key: REGULAR_PAD_KEY, name: REGULAR_PAD_KEY, price: prices.regularPadPrice });
  if (pn > 0) result.push({ count: pn, key: NIGHT_PAD_KEY, name: NIGHT_PAD_KEY, price: prices.nightPadPrice });
  if (pnp > 0) result.push({ count: pnp, key: NIGHT_PLUS_PAD_KEY, name: NIGHT_PLUS_PAD_KEY, price: prices.nightPlusPadPrice });
  if (pl > 0) result.push({ count: pl, key: LINER_PAD_KEY, name: LINER_PAD_KEY, price: prices.linerPadPrice });

  return result;
};

const getPadsBoxItems = (boxState: PadsBoxState, prices?: Prices) => {
  const { pr, pn, pnp, pl } = boxState;

  return [
    { count: pr, key: REGULAR_PAD_KEY, name: REGULAR_PAD_KEY, price: prices?.regularPadPrice ?? 0 },
    { count: pn, key: NIGHT_PAD_KEY, name: NIGHT_PAD_KEY, price: prices?.nightPadPrice ?? 0 },
    { count: pnp, key: NIGHT_PLUS_PAD_KEY, name: NIGHT_PLUS_PAD_KEY, price: prices?.nightPlusPadPrice ?? 0 },
    { count: pl, key: LINER_PAD_KEY, name: LINER_PAD_KEY, price: prices?.linerPadPrice ?? 0 }
  ];
};

const getPadsStackBoxItems = (boxState: PadsBoxStackState, prices?: Prices) => {
  const { pr, pn, pnp, pl } = boxState;

  return [
    { count: pl, key: LINER_PAD_KEY, name: LINER_PAD_KEY, price: prices?.linerPadPrice ?? 0 },
    { count: pr, key: REGULAR_PAD_KEY, name: REGULAR_PAD_KEY, price: prices?.regularPadPrice ?? 0 },
    { count: pn, key: NIGHT_PAD_KEY, name: NIGHT_PAD_KEY, price: prices?.nightPadPrice ?? 0 },
    { count: pnp, key: NIGHT_PLUS_PAD_KEY, name: NIGHT_PLUS_PAD_KEY, price: prices?.nightPlusPadPrice ?? 0 }
  ];
};

const generateCartIdSuffix = (boxState: BoxState) => {
  const { cbdsuper, cbdregular, nakedsuper, nakedregular } = boxState;
  return `${cbdregular}${cbdsuper}${nakedregular}${nakedsuper}`;
};

const generatePadsCartIdSuffix = (boxState: PadsBoxState) => {
  const { pr, pn, pnp, pl } = boxState;
  return `${pr}${pn}${pnp}${pl}`;
};

const isCartItemUnique = (productCode: ProductCode) => {
  if (productCode?.includes('ppc_')) {
    return true;
  }
  return false;
};

// Helper function to create a cart item
const createCartItemHelper = (
  productCode: ProductCode,
  quantity: number,
  price: number,
  editable: boolean,
  type: CartItemType,
  boxItems?: BoxItem[],
  cartIdSuffix?: string,
  subscriptionPlans?: SubscriptionPlan[],
  selectedPlan?: SubscriptionPlan,
  prices?: Prices
): CartItem => {
  switch (type) {
    case CartItemType.Consultation: {
      return {
        id: productCode,
        type: CartItemType.Consultation,
        price,
        productCode,
        quantity,
        editable,
        unique: isCartItemUnique(productCode)
      };
    }
    case CartItemType.OneOffPurchase: {
      if (productCode === ProductCode.TamponBox || productCode === ProductCode.Pads) {
        return {
          boxItems,
          id: cartIdSuffix ? `${productCode}_${cartIdSuffix}` : productCode,
          type,
          price,
          productCode,
          quantity,
          editable,
          unique: isCartItemUnique(productCode)
        };
      }

      let finalProductCode = productCode;
      if (productCode === ProductCode.ScreeningKit) {
        finalProductCode = ProductCode.VMSEssential;
      }

      return {
        id: finalProductCode,
        type: CartItemType.OneOffPurchase,
        price,
        productCode: finalProductCode,
        quantity,
        editable,
        unique: isCartItemUnique(productCode)
      };
    }
    case CartItemType.Subscription: {
      if (!subscriptionPlans && !selectedPlan) {
        return {
          boxItems,
          dependantCartItems: [],
          id: `subscription_${selectedPlan?.productCode}`,
          type: CartItemType.Subscription,
          selectedPlan: null,
          subscriptionType: null,
          billing: null,
          delivery: null,
          price,
          productCode,
          quantity,
          editable,
          subscriptionPlans,
          unique: true
        };
      }

      let dependantItems: CartItem[] | undefined = [];

      if (productCode === ProductCode.TamponBox) {
        const pouch = new CartItem('tampon_pouch_free', ProductCode.TamponPouch, 0);
        pouch.originalPrice = prices?.tamponPouchPrice;
        pouch.editable = false;
        pouch.unique = true;
        dependantItems = [pouch];
        // TODO - freeItem should be an array of productcodes
        // we should then have a badge key for the text to display on the multi-month item
        if (selectedPlan?.freeItem && selectedPlan?.freeItem.key === 'free-case') {
          const tin = new CartItem('tin_case_free', ProductCode.TamponTin, 0);
          tin.originalPrice = prices?.tamponTinPrice;
          tin.editable = false;
          tin.unique = true;
          dependantItems = [...dependantItems, tin];
        }
      }

      return {
        boxItems,
        dependantCartItems: dependantItems,
        id: `subscription_${selectedPlan.productCode}`,
        type: CartItemType.Subscription,
        subscriptionInterval: {
          length: selectedPlan.subscriptionInterval.length,
          unit: selectedPlan.subscriptionInterval.unit
        },
        selectedPlan,
        subscriptionType: selectedPlan.subscriptionType,
        billing: { installments: selectedPlan.billing.installments },
        delivery: { installments: selectedPlan.delivery.installments },
        price: selectedPlan.billing.installmentPrice ?? price,
        productCode,
        quantity,
        editable,
        subscriptionPlans,
        unique: true
      };
    }
    default:
      throw Error('Invalid cart item type.');
  }
};

export const createSimpleCartItemForProductCode = (
  productCode: ProductCode,
  quantity: number,
  price: number,
  editable: boolean,
  type = CartItemType.OneOffPurchase
): CartItem => createCartItemHelper(productCode, quantity, price, editable, type);

export const createCartItem = (
  productCode: ProductCode,
  quantity: number,
  price: number,
  editable: boolean,
  type: CartItemType,
  prices?: Prices,
  boxState?: BoxState | PadsBoxState | PadsBoxStackState,
  subscriptionPlans?: SubscriptionPlan[],
  selectedPlan?: SubscriptionPlan
): CartItem => {
  let boxItems: BoxItem[] = [];
  let cartIdSuffix = '';

  if (boxState) {
    switch (productCode) {
      case ProductCode.TamponBox:
        boxItems = getBoxItems(boxState as BoxState, prices);
        cartIdSuffix = generateCartIdSuffix(boxState as BoxState);
        break;
      case ProductCode.Pads: {
        const isStackBox = isBoxStatePadsStackBox(boxState as BoxState);

        boxItems = isStackBox ? getPadsStackBoxItems(boxState as PadsBoxStackState, prices) : getPadsBoxItems(boxState as PadsBoxState, prices);
        cartIdSuffix = isStackBox ? '' : generatePadsCartIdSuffix(boxState as PadsBoxState);
        break;
      }
      case ProductCode.ScreeningKit:
        boxItems = getBoxItems(boxState as BoxState);
        cartIdSuffix = `${boxItems[0].name}`;
        break;
      default:
        boxItems = [
          {
            count: 1,
            key: productCode,
            name: selectedPlan?.productCode ?? ''
          }
        ];
    }
  }

  return createCartItemHelper(productCode, quantity, price, editable, type, boxItems, cartIdSuffix, subscriptionPlans, selectedPlan, prices);
};

export const hasTamponsAndPads = (item: CartItem) => {
  const itemContainsPads = (boxItem: BoxItem) =>
    boxItem.count > 0 && (boxItem.key === REGULAR_PAD_KEY || boxItem.key === NIGHT_PAD_KEY || boxItem.key === NIGHT_PLUS_PAD_KEY);

  if (item.productCode === ProductCode.TamponBox && item?.boxItems?.length > 0) {
    const pads = item.boxItems?.filter(itemContainsPads);
    return pads.length > 0;
  }
  return false;
};

const tamponsKeys = [CBD_SUPER_TAMPON_KEY, CBD_REGULAR_TAMPON_KEY, NAKED_SUPER_TAMPON_KEY, NAKED_REGULAR_TAMPON_KEY];
const padsKeys = [REGULAR_PAD_KEY, NIGHT_PAD_KEY, NIGHT_PLUS_PAD_KEY, LINER_PAD_KEY];

const boxItemsReducer = (previousValue, currentValue, keySet: Array<string>) => {
  if (currentValue.key && keySet.includes(currentValue.key)) {
    return previousValue + currentValue.count;
  }
  return previousValue;
};

export const getBoxItemsCount = (boxItems: BoxItem[]) => boxItems.reduce((a, b) => boxItemsReducer(a, b, tamponsKeys.concat(padsKeys)), 0);
export const getBoxTamponsCount = (boxItems: BoxItem[]) => boxItems.reduce((a, b) => boxItemsReducer(a, b, tamponsKeys), 0);
export const getBoxPadsCount = (boxItems: BoxItem[]) => boxItems.reduce((a, b) => boxItemsReducer(a, b, padsKeys), 0);

export const filterBoxItemTampons = (boxItems: BoxItem[]) => boxItems.filter((item) => tamponsKeys.includes(item.key || ''));
export const filterBoxItemPads = (boxItems: BoxItem[]) => boxItems.filter((item) => padsKeys.includes(item.key || ''));

export const calculateTotalPrice = (cartItems: CartItem[]): number => {
  return cartItems.reduce((total, item) => total + item.price * item.quantity, 0);
};

/**
 * Combines a cartItem with addOnItems based on specific conditions, particularly focusing on the combination of pads and liners.
 * This function tracks whether any actual combination has occurred and returns the original cartItem and addOnItems unchanged if no combination is done.
 * This behavior ensures that the function can be seamlessly integrated into workflows where preserving the original state of items is crucial unless modifications are explicitly required.
 * This is only be applicable to tampon or pads boxes at the moment, and only until we introduce pre-packed boxes and not have customisation down to the tampon/pad count.
 *
 * @param {CartItem} cartItem - The main cart item, potentially to be combined with add-on items.
 * @param {CartItem[]} addOnItems - An array of additional items that may be combined with the main cart item based on certain conditions.
 * @param {CurrencyObj} currency - The currency object for pricing adjustments.
 * @param {CountryCode} country - The country code, used for any pricing calculations that may depend on the shopper's location.
 * @returns {Promise<{combinedCartItem: CartItem, remainingAddOnItems: CartItem[]}>} - An object containing the potentially modified main cart item and any remaining add-on items that were not combined. If no items were combined, the original cartItem and addOnItems are returned unchanged.
 */
export const combineCartItemsAndAddOns = async (cartItem: CartItem, addOnItems: CartItem[], currency: CurrencyObj, country: CountryCode) => {
  let isCombined = false;

  if (cartItem.productCode === ProductCode.Pads) {
    if (addOnItems.length > 0) {
      const combinedCartItem = { ...cartItem };
      const remainingAddOnItems: CartItem[] = [];
      let additionalPrice = 0;

      const promises = addOnItems.map(async (addOnItem: CartItem) => {
        if (!combinedCartItem.boxItems) return;
        if (addOnItem.productCode === ProductCode.PadsBox12L) {
          isCombined = true;
          const padsCount = 12;
          const plItem = combinedCartItem.boxItems.find((item) => item.key === LINER_PAD_KEY);
          const linerPrice = (await useBoxPrice({ pl: 1 } as Variant, currency, country, ProductCode.Pads)) ?? LINER_PAD_PRICE_DEFAULT;
          if (plItem) {
            plItem.count += padsCount;
          } else {
            combinedCartItem.boxItems.push({ count: padsCount, key: LINER_PAD_KEY, name: LINER_PAD_KEY, price: linerPrice });
          }
          additionalPrice += padsCount * linerPrice;
          combinedCartItem.id += `_${padsCount}pl`;
          combinedCartItem.price += additionalPrice;
        } else {
          remainingAddOnItems.push(addOnItem);
        }
      });

      await Promise.all(promises);

      if (isCombined) {
        return { combinedCartItem, remainingAddOnItems };
      }
    }
  }

  return { combinedCartItem: cartItem, remainingAddOnItems: addOnItems };
};

export const divideCartItems = (cartItem: CartItem, country: CountryCode) => {
  if (cartItem.type !== CartItemType.Subscription && isPadsAndStackBox(cartItem.productCode, country) && cartItem.boxItems) {
    const padStackBoxCartItems: CartItem[] = [];
    const cartStackBoxItems = cartItem.boxItems;
    const baseCartItem = { ...cartItem };

    cartStackBoxItems.forEach((cartStackBoxItem) => {
      if (cartStackBoxItem.key && cartStackBoxItem.price && cartStackBoxItem.count > 0) {
        const productCode = PAD_STACK_PRODUCT_IDS[cartStackBoxItem.key as PadSizeType];

        padStackBoxCartItems.push({
          ...baseCartItem,
          id: productCode,
          productCode,
          quantity: cartStackBoxItem.count,
          price: cartStackBoxItem.price,
          boxItems: []
        });
      }
    });

    return padStackBoxCartItems;
  }

  return [cartItem];
};

/**
 * Determines the type of cart item based on the provided product code.
 *
 * @param {ProductCode} productCode - The product code to analyze.
 * @returns {CartItemType} The corresponding cart item type.
 *
 */
export const getCartItemType = (productCode: ProductCode): CartItemType => {
  if (productCode.includes('consultation_')) {
    return CartItemType.Consultation;
  }

  if (productCode === ProductCode.GiftCard) {
    return CartItemType.GiftCard;
  }

  return CartItemType.OneOffPurchase;
};

export const convertBoxItems = (boxItems: BoxItem[] | undefined, productCode: ProductCode, country: CountryCode) => {
  if (!boxItems) {
    return [];
  }

  const filteredBoxItems = boxItems?.filter((bi) => bi.key != null) ?? [];

  if (isPadsAndStackBox(productCode, country)) {
    return convertPadsStackBoxItems(filteredBoxItems);
  }

  return filteredBoxItems;
};

/**
 * Generates line items for Apple Pay based on cart items, shipping cost, and tax.
 * @param {CartItem[]} cartItems - The items in the cart.
 * @param {number} shippingPrice - The cost of shipping.
 * @param {number} tax - The tax amount.
 * @returns {Array<{label: string, amount: string}>} The line items for Apple Pay.
 */
export const getApplePayLineItems = (cartItems: CartItem[], shippingPrice: number, tax: number) => {
  const lineItems = [];
  cartItems.forEach((item) => {
    const productName = getProductTitle(item.productCode);
    lineItems.push({ label: productName, amount: item.price.toFixed(2) });
  });
  if (shippingPrice > 0) {
    lineItems.push({ label: 'Shipping', amount: shippingPrice.toFixed(2) });
  }
  if (tax > 0) {
    lineItems.push({ label: 'Tax', amount: tax.toFixed(2) });
  }
  return lineItems;
};

/**
 * Generates line items for Google Pay based on cart items, shipping cost, and tax.
 * @param {CartItem[]} cartItems - The items in the cart.
 * @param {number} shippingPrice - The cost of shipping.
 * @param {number} tax - The tax amount.
 * @returns {GooglePayDisplayItem[]} The display items data for Google Pay.
 */
export const getGooglePayLineItems = (cartItems: CartItem[], shippingPrice: number, tax: number): GooglePayDisplayItem[] => {
  const lineItems = cartItems.map((item) => {
    const productName = getProductTitle(item.productCode);
    return {
      label: productName,
      price: item.price.toFixed(2),
      type: 'LINE_ITEM',
      status: 'FINAL'
    };
  });

  if (shippingPrice > 0) {
    lineItems.push({ label: 'Shipping', price: shippingPrice.toFixed(2), type: 'LINE_ITEM', status: 'FINAL' });
  }

  if (tax > 0) {
    lineItems.push({ label: 'Tax', price: tax.toFixed(2), type: 'LINE_ITEM', status: 'FINAL' });
  }

  return lineItems;
};

export const hasSubscriptionCartItems = (cartItems: CartItems): boolean => {
  return cartItems.some((item) => item.type === CartItemType.Subscription);
};

export const hasEligibleCartItems = (cartItems: CartItems, country: CountryCode): boolean => {
  return cartItems.some((item) => isHsaFsaAvailable(country, item.id as ProductCode));
};

/**
 * Calculates the total quantity of "paid" cart items.
 *
 * This function iterates over an array of cart items and sums the quantities
 * of items that have a price greater than 0. Items with a zero (or falsy) price
 * are considered free and are not counted.
 *
 * @param {CartItem[]} cartItems - The array of cart items to calculate the paid count from.
 * @returns {number} The total quantity of paid cart items.
 */
export function calculatePaidCartItemsCount(cartItems: CartItem[]): number {
  return cartItems.reduce((total: number, cartItem: CartItem) => {
    if (cartItem.price > 0) {
      return total + (cartItem.quantity ?? 0);
    }
    return total;
  }, 0);
}

export function createFreeGiftWithProductCode(productCode: ProductCode, country: CountryCode, currency: CurrencyObj, type: CartItemGiftType) {
  let originalPrice = 0;
  const pricesData = getFromLocalStorage('productPrices', true);
  if (pricesData && Array.isArray(pricesData)) {
    const product = pricesData.find((itm) => itm.id === productCode);
    if (product && product.price) {
      const vatPercent = product?.vat[country] ?? 0;
      const price = product.price.find((p) => p.currency === currency.letterCode);
      if (price) {
        const vatAmount = price.amount * (vatPercent / 100);
        const finalPrice = price.amount + vatAmount;
        originalPrice = finalPrice;
      }
    }
  }
  const cartItemType = getCartItemType(productCode);
  return new CartItem(`${productCode}_free`, productCode, 0, 1, cartItemType, false, type, originalPrice);
}

/**
 * Checks for and returns any free gifts that should be added based on active promotions.
 *
 * @param cartItem - The cart item being added/modified that triggered this check
 * @param country - The current country code (e.g. 'GB', 'US')
 * @param currency - The currency object containing letterCode and symbol
 * @param price - The current cart total price
 * @returns Array of CartItem objects representing free gifts to be added
 */
export const getFreeGiftsForPromotion = (cartItem: CartItem, country: CountryCode, currency: CurrencyObj, price: number): CartItem[] => {
  const freeGifts: CartItem[] = [];
  const promotions: PromotionObject[] = getFromLocalStorage('promotions', true) ?? [];

  promotions.forEach((promo) => {
    const isPromoActive = isPromotionActive(promo, country, cartItem.productCode);
    const cartItemIsFreeGift = cartItem.id?.includes('_free');
    const meetsSpendRequirement = promo.spendRequirement ? price >= promo.spendRequirement[country] : true;
    const isValidPromotion = (!promo.couponCode && meetsSpendRequirement) || promo.couponCode;
    const shouldAddFreeGift = isPromoActive && promo.freeGift && !cartItemIsFreeGift && isValidPromotion;

    if (shouldAddFreeGift) {
      const freeGiftProductCode = promo.freeGift as ProductCode;
      const freeGift = createFreeGiftWithProductCode(freeGiftProductCode, country, currency, CartItemGiftType.PromotionCode);
      freeGifts.push(freeGift);
    }
  });

  return freeGifts;
};
