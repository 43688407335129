/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import { hydrateRoot, createRoot } from 'react-dom/client';
import consoleIgnoreCriteria from './src/data/consoleIgnoreCriteria';
import wrapWithProvider from './wrap-with-provider';

export const wrapRootElement = wrapWithProvider;

export const replaceHydrateFunction = () => (Component, el) => {
  const useHydrate = el && el?.children?.length;

  const hydrationOptions = {
    onRecoverableError: (error) => {
      console.error('Recovered from error during hydration: ', error);
    }
  };

  try {
    if (useHydrate) {
      const root = hydrateRoot(el, Component, hydrationOptions);

      return () => root.unmount();
    }

    const root = createRoot(el, hydrationOptions);

    root.render(Component);

    return () => root.unmount();
  } catch (error) {
    console.error('Catched hydration error: ', error);
  }
};

export const onRouteUpdate = () => {
  window.locations = window.locations || [document.referrer];

  const currentPath = window.location.pathname;

  if (window.locations[window.locations.length - 1] !== currentPath) {
    window.locations.push(currentPath);
  }

  window.previousPath = window.locations[window.locations.length - 2];
};

if (process.env.NODE_ENV === 'development') {
  const overrideConsoleMethod = (methodName, originalMethod) => {
    return (...args) => {
      if (
        args[0] &&
        typeof args[0] === 'string' &&
        consoleIgnoreCriteria[methodName].some(ignoreString => args[0].includes(ignoreString))
      ) {
        return;
      }
      originalMethod(...args);
    };
  };

  console.warn = overrideConsoleMethod('warn', console.warn);
  console.error = overrideConsoleMethod('error', console.error);
}