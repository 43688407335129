// always have a default locale
module.exports = [
  {
    label: 'English GB',
    default: true,
    path: 'en',
    locale: 'en-gb'
  },
  {
    label: 'English US',
    default: false,
    path: 'en-us',
    locale: 'en-us'
  },
  {
    label: 'English EU',
    default: false,
    path: 'en-eu',
    locale: 'en-eu'
  },
  {
    label: 'English IE',
    default: false,
    path: 'en-ie',
    locale: 'en-ie'
  },
  {
    label: 'English DE',
    default: false,
    path: 'en-de',
    locale: 'en-de'
  },
  {
    label: 'English BG',
    default: false,
    path: 'en-bg',
    locale: 'en-bg'
  }
];
