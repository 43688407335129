const Colors = {
  ecru: '#FAEAD6',
  ecru50: '#FCF2DE',
  ecru75: '#FBEFE0',
  navyBlue: '#001D3B',
  gold: '#EBA820',
  paleGold: '#F9D07C',
  paleGold75: '#FBDC9D',
  paleGold50: '#FBE5B1',
  paleGold25: '#FDF2D8',
  darkGreen: '#003B1B',
  cream: '#FFFBE7',
  pink: '#d16aa9',
  lilac: '#D16AA9',
  forestGreen: '#003B1B',
  mint: '#E5F2E3',
  mint25: '#F9FCF8',
  mint50: '#F1F6E4',
  mint75: '#EBF5EA',
  eucalyptus: '#A2BBAC',
  babyBlue25: '#FAFBFD',
  babyBlue50: '#F5F8FA',
  babyBlue: '#EBF1F5',
  sage: '#C5E3C1',
  sage25: '#F0F7E9',
  sage50: '#E1EED3',
  sage75: '#D3EAD1',
  red: '#ea534f',
  sky: '#dce9f2',
  sky50: '#EDF4F9',
  carnationPink: '#f9b2a9',
  carnationPink25: '#FFEDEB',
  carnationPink50: '#FFDAD6',
  carnationPink75: '#FBC5BF',
  powderPink: '#faeeeb',
  melon: '#fcc79a',
  paleGreen: '#e0efdc',
  paleBlue: 'rgb(220, 233, 242)',
  palePink: 'rgb(250, 238, 235)',
  creamPink: 'rgb(250, 234, 214)',
  thirdPink: '#fef1e8',
  midGreen: '#c4e4c1',
  creamGold: '#FAEDCB',
  provioticsBlue: '#dfebf3',
  provioticsPink: '#f9b1a9',
  provioticsDarkBlue: '#061d38'
};

export default Colors;
