import { PromotionObject } from '@contracts';

export const isPromotionActive = (promo: PromotionObject, country: string, productCode?: string): boolean => {
  const currentDate = new Date();
  const startDate = new Date(promo.startDate);
  const endDate = new Date(promo.endDate);
  const isInCountry = promo.countries.length === 0 || promo.countries.includes(country);
  const isDateValid = currentDate >= startDate && currentDate <= endDate;
  const isProductValid = !promo.productCodes || promo.productCodes.length === 0 || !productCode || promo.productCodes.includes(productCode);

  return isDateValid && isInCountry && isProductValid;
};
