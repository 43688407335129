import { CountryCode, SubscriptionPlan, ProductCodeKey } from '@dayetopia/types';
import { ProductCode } from '@contracts';
import checkResStatus from '@utils/checkResStatus';
import getOmsUrl from '@utils/getOmsUrl';

export const handleFetchProductPriceAndSubscriptionPlans = async (basePrice: number, productCode: string, country: CountryCode) => {
  if (!productCode) return [];
  const plans = await getSubscriptionPlans(productCode as ProductCode);
  const updatedPlans = plans.map((p: SubscriptionPlan) => {
    const discountPercent = p.discountPercent[country] ?? p.discountPercent.default;
    const itemPrice = basePrice - (basePrice * discountPercent) / 100;
    const discountedPrice = productCode === ProductCode.Pads ? (itemPrice * 20) / 20 : Math.round(itemPrice * 20) / 20;
    const installmentPrice = calculateInstallmentPrice(p, discountedPrice);
    return {
      ...p,
      basePrice,
      discountedPrice,
      billing: { ...p.billing, installmentPrice }
    };
  });

  updatedPlans.sort((a, b) => a.discountPercent.default - b.discountPercent.default);

  return updatedPlans;
};

const calculateInstallmentPrice = (subscription: SubscriptionPlan, discountedPrice: number) => {
  if (subscription.productCode === ProductCodeKey.ScreeningKit) {
    return discountedPrice * subscription.delivery.installments;
  }
  if (subscription.productCode === ProductCodeKey.Pads) {
    return (discountedPrice * subscription.subscriptionInterval.length) / subscription.billing.installments;
  }
  return (discountedPrice * subscription.subscriptionInterval.length) / subscription.billing.installments;
};

export const getSubscriptionPlans = async (productCode: ProductCode): Promise<SubscriptionPlan[]> => {
  const subscriptionProductCodes = [
    ProductCode.Pads,
    ProductCode.ScreeningKit,
    ProductCode.TamponBox,
    ProductCode.Proviotics,
    ProductCode.PPCTamponBox
  ];
  if (!subscriptionProductCodes.includes(productCode)) return [];
  const subscriptionPlans = await getPlans();
  return subscriptionPlans;

  async function getPlans() {
    const oms = getOmsUrl();
    const url = `${oms}/ecommerce/subscriptions/${productCode}`;
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      }
    };
    return fetch(url, options)
      .then((res) => checkResStatus(res))
      .then((json) => json)
      .catch((err) => {
        throw err;
      });
  }
};
