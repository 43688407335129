import React, { lazy } from 'react';
import { LocationProvider } from '@reach/router';
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Provider } from '@components/Context';
import { NavProvider } from '@components/navigation/Context';
import { linkResolver } from './linkResolver';
import { RECAPTCHA_ID } from './src/constants';
import { extractLanguageFromUrl, getCountryCodeByLocale } from './src/utils/language';

export default function Wrapper({ element, pathname }) {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  const currentPathname = typeof window !== 'undefined' ? window?.location?.pathname : pathname;
  const country = getCountryCodeByLocale(extractLanguageFromUrl(currentPathname)?.locale);

  return (
    <LocationProvider>
      <Provider country={country}>
        <NavProvider>
          <PrismicPreviewProvider
            repositoryConfigs={[
              {
                repositoryName: 'ecommerce-website',
                typePrefix: 'ecommerce',
                componentResolver: {
                  page: lazy(() => import('@components/page/Page')),
                  product: lazy(() => import('@components/products/ProductPage'))
                },
                linkResolver
              }
            ]}
          >
            {element}
          </PrismicPreviewProvider>
        </NavProvider>
        <RecaptchaContainer id={RECAPTCHA_ID} />
      </Provider>
    </LocationProvider>
  );
}

Wrapper.propTypes = {
  element: PropTypes.node,
  pathname: PropTypes.string
};

const RecaptchaContainer = styled.div`
  & > .grecaptcha-badge {
    visibility: hidden;
    margin-bottom: 75px;
  }
`;
