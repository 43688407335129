import { CountryCode } from '@dayetopia/types';
import { DEFAULT_LOCALE, LIVE_DOMAIN_WITHOUT_SLASH } from '@constants';
import { Locale } from '@contracts.js';
import i18n from '../../i18n';

/**
 * Determines whether a given URL is an internal link.
 *
 * @param {string} path - The URL to check.
 * @return {boolean} True if the URL is internal, false otherwise.
 */
export const isInternalLink = (path: string) => {
  return path.startsWith('/') && !path.startsWith('//');
};

/**
 * Returns the localized URL for a given path. To be used only for LinkTranslated internal links that are have target blank, as it's not supported by Gatsby Link component.
 *
 * @param {string} path - The path to localize.
 * @param {string} [currentLanguage] - The current language (optional). If not provided, the current page language is used.
 * @param {boolean} [noInternalCheck] - No internal link check (optional). If not provided, the function will check if the link is internal or not, this parameter is used to avoid additional check if that is already checked.
 * @return {string} The localized path. Adds the language prefix to internal links if the language is not default.
 */
export const getLocaleUrlForPath = (path: string, currentLanguage?: string, noInternalCheck?: boolean) => {
  if (!noInternalCheck && !isInternalLink(path)) {
    return path;
  }

  const finalCurrentLanguage = currentLanguage || i18n.find((language: Locale) => language.default)?.path;
  const lang = i18n.find((language: Locale) => language.path === finalCurrentLanguage);

  let finalPath = path;

  // Reset language prefixes if any
  i18n.forEach((language: Locale) => {
    finalPath = removeLanguageIfInPath(finalPath, language.path);
  });

  // Add language path only if it's not the default language
  if (lang && !lang.default) {
    return `/${lang.path}${finalPath}`;
  }

  return finalPath;
};

/**
 * Removes the default language prefix from a URL path.
 *
 * @param {string} path - The URL path to process.
 * @param {string} language - The language code to remove.
 * @returns {string} - The path without the language prefix.
 */
export function removeLanguageIfInPath(path: string, language: string): string {
  let finalPath = path;
  const languageLocale = findLocaleByLanguagePath(language);

  if (languageLocale?.locale && languageLocale.locale !== language) {
    finalPath = finalPath.replace(new RegExp(`^/${languageLocale.locale}/`), '/');
  }

  finalPath = finalPath.replace(new RegExp(`^/${language}/`), '/');

  return finalPath;
}

/**
 * Returns the locale for a given country code. Default is en-gb.
 *
 * @param {CountryCode} countryCode - The country code to get the locale for.
 * @returns {string} The locale for the given country code.
 */
export function getLocaleByCountryCode(countryCode: CountryCode): string {
  switch (countryCode) {
    case CountryCode.US:
      return 'en-us';
    case CountryCode.NL:
    case CountryCode.BE:
    case CountryCode.IT:
    case CountryCode.ES:
    case CountryCode.PT:
    case CountryCode.FR:
    case CountryCode.SE:
    case CountryCode.DK:
    case CountryCode.NO:
    case CountryCode.FI:
    case CountryCode.AT:
    case CountryCode.CH:
      return 'en-eu';
    case CountryCode.IE:
      return 'en-ie';
    case CountryCode.DE:
      return 'en-de';
    case CountryCode.BG:
      return 'en-bg';
    default:
      return 'en-gb';
  }
}

/**
 * Returns the country code for a given locale.
 *
 * @param {string} locale - The locale to get the country code for.
 * @returns {CountryCode} The country code for the given locale.
 */
export function getCountryCodeByLocale(locale: string): CountryCode {
  switch (locale) {
    case 'en-us':
      return CountryCode.US;
    case 'en-bg':
      return CountryCode.BG;
    case 'en-ie':
      return CountryCode.IE;
    case 'en-eu':
      return CountryCode.FR;
    default:
      return CountryCode.GB;
  }
}

/**
 * Finds the locale for a given language path.
 *
 * @param {string} language - The language path to find the locale for.
 * @returns {string | undefined} The locale if found, otherwise undefined.
 */
export function findLocaleByLanguagePath(language: string): Locale | undefined {
  return i18n.find((locale: Locale) => locale.path === language);
}

/**
 * Finds the locale for a given locale string.
 *
 * @param {string} localeString - The locale string to find the locale for.
 * @returns {Locale | undefined} The locale if found, otherwise undefined.
 */
export function findLocaleByLocaleString(localeString: string): Locale | undefined {
  return i18n.find((locale: Locale) => locale.locale === localeString);
}

/**
 * Strips the locale prefix from a given URL path based on a list of known locale paths.
 * This function dynamically creates a regular expression from a list of locales to match
 * and remove the prefix if present. It is designed to handle dynamic locale prefixes
 * configured in the application.
 *
 * @param {string} path - The URL path from which the locale prefix is to be removed.
 * @returns {string} The URL path with the locale prefix stripped off if present.
 *                   Returns the original path if no locale prefix is matched.
 *
 * @example
 * // returns '/about/' assuming 'en-us' is part of the locale paths
 * stripLocaleFromPath('/en-us/about/');
 *
 * @example
 * // returns '/contact/' assuming 'en-eu' is part of the locale paths
 * stripLocaleFromPath('/en-eu/contact/');
 *
 * @example
 * // returns '/blog/' if no matching locale prefix is found
 * stripLocaleFromPath('/blog/');
 */
export function removeLocalesFromPath(path: string): string {
  let finalPath = path;

  i18n.forEach((locale: Locale) => {
    finalPath = finalPath.replace(new RegExp(`^/${locale.path}/`), '/');

    if (locale.locale) {
      finalPath = finalPath.replace(new RegExp(`^/${locale.locale}/`), '/');
    }
  });

  return finalPath;
}

/**
 * Extracts the language from the URL.
 *
 * @param {string} pathname - The pathname to extract the language from.
 * @returns {Locale} The locale if found, otherwise undefined.
 */
export function extractLanguageFromUrl(pathname: string): Locale {
  const pathSegments = pathname?.split('/');
  const languageFromUrl = pathSegments?.length > 1 ? pathSegments[1] : undefined;
  const localeFromUrl = languageFromUrl ? findLocaleByLanguagePath(languageFromUrl) : '';

  return localeFromUrl || i18n.find((language: Locale) => language.default)!;
}

/**
 * Finds the nodes for the given language or falls back to the default locale.
 *
 * @param {any[]} nodes - The array of nodes to search through.
 * @param {string} currentLanguage - The current language to find nodes for.
 * @param {string} defaultLocale - The default language to find nodes for.
 * @returns {any | undefined} The nodes for the given language or the default locale.
 */
export const getNodesForLocale = (nodes: any[], currentLanguage: string, defaultLocale = DEFAULT_LOCALE): any | undefined => {
  return nodes.find((node: { lang: string }) => node.lang === currentLanguage) || nodes.find((node: { lang: string }) => node.lang === defaultLocale);
};

/**
 * Merges nodes from current language with default language nodes.
 * Localized nodes override default nodes with matching UIDs.
 *
 * @param {any[]} nodes - Array of nodes to merge
 * @param {string} currentLanguage - Target language code (e.g. 'en-us')
 * @param {string} defaultLocale - Default language code (e.g. 'en-gb')
 * @returns {any[]} Combined array where localized nodes override matching default nodes
 * @example
 * // With nodes: [
 * //   {lang: 'en-us', uid: 'a', data: {title: 'US localised'}},
 * //   {lang: 'en-gb', uid: 'a', data: {title: 'GB localised'}},
 * //   {lang: 'en-gb', uid: 'b', data: {title: 'GB'}}
 * // ]
 * // Returns for US: [
 * //   {lang: 'en-us', uid: 'a', data: {title: 'US localised'}},
 * //   {lang: 'en-gb', uid: 'b', data: {title: 'GB'}}
 * // ]
 */
export const mergeLocalisedNodesWithDefault = (nodes: any[], currentLanguage: string, defaultLocale = DEFAULT_LOCALE): any[] => {
  const currentLangNodes = nodes.filter((node: { lang: string }) => node.lang === currentLanguage);
  const defaultLangNodes = nodes.filter((node: { lang: string }) => node.lang === defaultLocale);

  const mergedNodes = [...defaultLangNodes];

  currentLangNodes.forEach((localNode) => {
    const defaultIndex = mergedNodes.findIndex((node) => node.uid === localNode.uid);
    if (defaultIndex >= 0) {
      mergedNodes[defaultIndex] = localNode;
    } else {
      mergedNodes.push(localNode);
    }
  });

  return mergedNodes;
};

/**
 * Replaces the LIVE_DOMAIN with an empty string if it's used in the URL.
 *
 * @param {string} url - The original URL to process.
 * @returns {string} The processed URL.
 */
export function getLocalUrlIfLiveOneUsed(url: string): string {
  const replacedUrl = url.startsWith(LIVE_DOMAIN_WITHOUT_SLASH) ? url.replace(LIVE_DOMAIN_WITHOUT_SLASH, '') : url;

  return replacedUrl || '/';
}
